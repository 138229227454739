import {MouseEventHandler, ReactNode} from 'react'
import styled from 'styled-components'

const SIZE = '2.625rem'

interface TipsButtonProps {
  children?: ReactNode
  disabled?: boolean
  selected?: boolean
  error?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = styled.button.attrs(({theme}) => ({
  style: {
    ...theme.typography.ratingLabel,
  },
}))<TipsButtonProps>`
  width: ${SIZE};
  height: ${SIZE};
  padding: 0;
  border: none;
  border-radius: 50%;
  border: 2px solid ${({theme, error}) => (error ? theme.colors.error : theme.colors.item1)};
  background-color: ${({theme, selected}) => (selected ? theme.colors.item1 : '#ececec')};
  color: ${({theme}) => theme.colors.primaryText};
  box-shadow: 0 3px 12px rgba(218, 218, 218, 0.4), 0 2px 3px rgba(218, 218, 218, 0.16);
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
`

const TipsButton = (props: TipsButtonProps) => {
  return (
    <Button
      type="button"
      selected={props.selected}
      disabled={props.disabled}
      error={props.error}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export default TipsButton
