import {memo} from 'react'
import styled from 'styled-components'

import OptionIcon from 'src/assets/icons/option.svg?react'
import OptionSelectedIcon from 'src/assets/icons/option-selected.svg?react'

interface SelectOptionProps {
  selected?: boolean
  onSelect?: () => void
}

const Container = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  display: flex;
`

const SelectOption = (props: SelectOptionProps) => {
  return <Container onClick={props.onSelect}>{!props?.selected ? <OptionIcon /> : <OptionSelectedIcon />}</Container>
}

export default memo(SelectOption)
