import {ComponentPropsWithoutRef} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import InfoAlternativeIcon from 'src/assets/icons/info-alternative.svg?react'

interface InfoButtonProps extends ComponentPropsWithoutRef<'button'> {}

const Button = styled.button`
  ${({theme}) => ({...theme.typography.buttonText})};
  border: none;
  padding: 0 0.9375rem;
  background-color: ${({theme}) => theme.colors.whiteItem};
  border-radius: 2.109375rem;
  box-shadow: 0px 5px 14px 0px rgba(61, 72, 135, 0.04), 0px 2px 5px 0px rgba(61, 72, 135, 0.02);
  line-height: 0;
  overflow: hidden;
  color: ${({theme}) => theme.colors.primaryText};
  height: 2.8125rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 0.625rem;

  :enabled {
    cursor: pointer;
  }

  @media ${({theme}) => theme.queries.mobile} {
    height: 2.5625rem;
  }
`

const InfoAlternativeIconStyled = styled(InfoAlternativeIcon as AnyStyledComponent)`
  width: auto;
  height: 1.5625rem;
  color: ${({theme}) => theme.colors.primaryText};
`

const InfoButton = ({children, ...props}: InfoButtonProps) => {
  return (
    <Button type="button" {...props}>
      {children}
      <InfoAlternativeIconStyled />
    </Button>
  )
}

export default InfoButton
