import styled from 'styled-components'
import {forwardRef, useImperativeHandle, useRef} from 'react'

import Text from 'src/components/Text'
import {sizes} from 'src/utilities/theme'

const DATES_CONTAINER_PADDING = 12
const DATE_WIDTH = 92

interface DatesNavbarProps {
  dates?: string[]
  activeDate?: string
  onDateClick?: (date: string) => void
}

export interface DatesNavbarRef {
  scrollToDate: (date: string) => void
}

interface DateContainerProps {
  active?: boolean
}

const DatesContainer = styled.div`
  overflow-x: auto;
  height: ${({theme}) => theme.sizes.categoriesListContainerHeight}px;
  background-color: ${({theme}) => theme.colors.background};
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
  padding: 0 ${DATES_CONTAINER_PADDING}px;
`

const DateContainer = styled.button<DateContainerProps>`
  padding: 0.75rem 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.152453);
  border-radius: 1.344rem;
  background-color: ${({theme, active}) => (active ? theme.colors.secondaryText : theme.colors.alternativePrimaryText)};
  border: none;
  cursor: pointer;
  min-width: ${DATE_WIDTH}px;
  max-width: ${DATE_WIDTH}px;
`

const DatesNavbar = forwardRef<DatesNavbarRef, DatesNavbarProps>(({dates, activeDate, onDateClick}, ref) => {
  const datesContainerRef = useRef<HTMLDivElement>(null)
  const datePositions = useRef<{[date: string]: number}>({})

  const setDatePositions = (itemRef: HTMLButtonElement | null, date: string) => {
    if (!itemRef) {
      return
    }

    const halfPageContainerWidth = sizes.maxPageContainerWidth / 2
    const halfDateWidth = DATE_WIDTH / 2
    const hallfDatesContainerPadding = DATES_CONTAINER_PADDING / 2

    const scrollPosition = itemRef.offsetLeft - halfPageContainerWidth + halfDateWidth + hallfDatesContainerPadding

    datePositions.current[date] = scrollPosition > 0 ? scrollPosition : 0
  }

  const scrollToDate = (date: string) => {
    const scrollPosition = datePositions.current[date]
    if (!scrollPosition) {
      return
    }

    datesContainerRef.current?.scroll({
      left: scrollPosition,
      behavior: 'smooth',
    })
  }

  useImperativeHandle(
    ref,
    () => {
      return {scrollToDate}
    },
    [],
  )

  return (
    <DatesContainer ref={datesContainerRef}>
      {dates?.map((date) => {
        const active = date === activeDate

        return (
          <DateContainer
            ref={(itemRef) => setDatePositions(itemRef, date)}
            key={date}
            active={active}
            onClick={() => onDateClick?.(date)}
          >
            <Text
              type="productPageDescription"
              color={active ? 'alternativePrimaryText' : 'primaryText'}
              align="center"
            >
              {date}
            </Text>
          </DateContainer>
        )
      })}
    </DatesContainer>
  )
})

export default DatesNavbar
