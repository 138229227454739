import {Carousel as RRCarousel, CarouselProps as RRCarouselProps} from 'react-responsive-carousel'
import styled from 'styled-components'

interface CarouselProps extends Partial<RRCarouselProps> {}

interface IndicatorProps {
  isSelected?: boolean
}

const Container = styled.div`
  @media ${({theme}) => theme.queries.mobile} {
    ul.control-dots {
      top: 0;
      bottom: auto;
    }
  }
`

const Indicator = styled.button.attrs<IndicatorProps>({
  type: 'button',
})<IndicatorProps>`
  padding: 0;
  border: none;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.875rem 0 ${({theme}) => theme.colors.item3}40;
  opacity: ${({isSelected}) => (isSelected ? 1 : 0.5)};
  background-color: ${({theme}) => theme.colors.whiteItem};
  display: inline-block;
  margin: 0 0.5rem;
  overflow: hidden;

  :enabled {
    cursor: pointer;
  }
`

const Carousel = ({...props}: CarouselProps) => {
  return (
    <Container>
      <RRCarousel
        infiniteLoop
        emulateTouch
        useKeyboardArrows
        autoPlay
        interval={5000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        renderIndicator={(clickHandler, isSelected) => <Indicator isSelected={isSelected} onClick={clickHandler} />}
        {...props}
      />
    </Container>
  )
}

export default Carousel
