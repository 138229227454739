import {ButtonHTMLAttributes} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'
import {useMediaQuery, useValueByScroll} from 'src/utilities/hooks'
import {ProductCategoryContract} from 'src/types/api'
import {mediaUrl} from 'src/utilities/functions'

const CATEGORY_NORMAL_PADDING_BOTTOM = 48 as const
const CATEGORY_SMALL_PADDING_BOTTOM = 24 as const
const CATEGORY_PADDING_BOTTOM_DIFFERENCE = CATEGORY_NORMAL_PADDING_BOTTOM - CATEGORY_SMALL_PADDING_BOTTOM

const TITLE_MOBILE_NORMAL_HEIGHT = 40 as const
const TITLE_MOBILE_SMALL_HEIGHT = 32 as const
const TITLE_MOBILE_NORMAL_HEIGHT_DIFFERENCE = TITLE_MOBILE_NORMAL_HEIGHT - TITLE_MOBILE_SMALL_HEIGHT

interface CategoryProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  category?: ProductCategoryContract
  selected?: boolean
  transitionTime?: number
  offsetTop?: number
  countTo?: number
  onCategoryClick?: (id: string) => void
}

interface CategoryContainerProps {
  url?: string
  selected?: boolean
  transitionTime?: number
  paddingBottom?: number
}

interface CategoryTitleContainerProps {
  selected?: boolean
  transitionTime?: number
  height?: number
}

const CategoryContainer = styled.button<CategoryContainerProps>`
  display: grid;
  justify-items: center;
  align-items: end;
  width: 100%;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.875rem 0 ${({theme}) => theme.colors.item3}26;
  background: url(${({url}) => url}) #d3d3d3 50% / cover no-repeat;
  padding: ${({selected, paddingBottom = 0}) =>
    selected
      ? `0.5rem 0.5rem ${CATEGORY_NORMAL_PADDING_BOTTOM - paddingBottom - 2}px 0.5rem`
      : `0.625rem 0.625rem ${CATEGORY_NORMAL_PADDING_BOTTOM - paddingBottom}px 0.625rem`};
  border: ${({selected, theme}) => (selected ? `0.125rem solid ${theme.colors.primaryItem}` : 'none')};
  transition: all ${({transitionTime}) => `${transitionTime}ms`};

  @media ${({theme}) => theme.queries.mobile} {
    padding: ${({selected}) => (selected ? 0.5 : 0.625)}rem;
  }

  :enabled {
    cursor: pointer;
  }
`

const CategoryTitleContainer = styled.div<CategoryTitleContainerProps>`
  padding: 0 0.5rem;
  max-width: 15rem;
  width: 100%;
  height: 3.75rem;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0 0.25rem 0.875rem 0 ${({theme}) => theme.colors.item3}40;
  background-color: ${({selected, theme}) => (selected ? theme.colors.primaryItem : theme.colors.whiteItem)};
  display: grid;
  place-items: center;
  transition: all ${({transitionTime}) => `${transitionTime}ms`};
  box-sizing: border-box;
  opacity: 0.85;

  @media ${({theme}) => theme.queries.mobile} {
    max-width: none;
    height: ${({height = 0}) => TITLE_MOBILE_NORMAL_HEIGHT - height}px;
  }
`

const CategoryTitle = styled(Text as unknown as AnyStyledComponent).attrs({
  align: 'center',
  style: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
})``

const Category = ({
  category,
  selected,
  transitionTime,
  offsetTop = 0,
  countTo = 0,
  onCategoryClick,
  ...props
}: CategoryProps) => {
  const {isMobile} = useMediaQuery()

  const categoryPaddingBottom = useValueByScroll(CATEGORY_PADDING_BOTTOM_DIFFERENCE, countTo, offsetTop)
  const titleHeight = useValueByScroll(TITLE_MOBILE_NORMAL_HEIGHT_DIFFERENCE, countTo, offsetTop)

  const handleCategoryClick = () => {
    if (!category) {
      return
    }

    onCategoryClick?.(category.id!)
  }

  return (
    <CategoryContainer
      type="button"
      transitionTime={transitionTime}
      url={mediaUrl(category?.imageName, {w: 1680, h: 660})}
      selected={selected}
      paddingBottom={categoryPaddingBottom}
      onClick={handleCategoryClick}
      {...props}
    >
      <CategoryTitleContainer selected={selected} transitionTime={transitionTime} height={titleHeight}>
        <CategoryTitle type={isMobile ? 'productDescription' : 'modalOptions'}>{category?.title}</CategoryTitle>
      </CategoryTitleContainer>
    </CategoryContainer>
  )
}

export default Category
