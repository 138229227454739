import {useSelector} from 'react-redux'
import {DefaultTheme} from 'styled-components'
import {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react'

import {RootState} from './store'
import {loadFont} from './functions'
import {IS_STAGING} from './constants'

export const DEFAULT_FONT_FAMILY = 'Roboto, sans-serif' as const

export type Typography = keyof DefaultTheme['typography']
export type Color = keyof DefaultTheme['colors']

const typography = (fontFamily: CSSProperties['fontFamily']): DefaultTheme['typography'] => {
  return {
    productBoxName: {
      fontFamily,
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    productBoxDescription: {
      fontFamily,
      fontSize: '0.75rem',
      lineHeight: 'normal',
    },
    productBoxPrice: {
      fontFamily,
      fontSize: '1rem',
      fontWeight: 500,
    },
    productBoxStrikethroughPrice: {
      fontFamily,
      fontSize: '1rem',
      textDecoration: 'line-through',
    },
    verticalMenu: {
      fontFamily,
      fontSize: '1.1875rem',
    },
    horizontalMenu: {
      fontFamily,
      fontSize: '0.9375rem',
    },
    productPageTitle: {
      fontFamily,
      fontSize: '1.5625rem',
      fontWeight: 500,
    },
    productPageDescription: {
      fontFamily,
      fontSize: '0.9375rem',
    },
    productPageOptionName: {
      fontFamily,
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    productPageOptionDescription: {
      fontFamily,
      fontSize: '0.9375rem',
    },
    productPageOptionSelection: {
      fontFamily,
      fontSize: '0.9375rem',
    },
    checkoutPageTitle: {
      fontFamily,
      fontSize: '1.5625rem',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    checkoutPageOptionTitle: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: 'normal',
    },
    checkoutPageOptionDescription: {
      fontFamily,
      fontSize: '0.9375rem',
      lineHeight: 'normal',
    },
    checkoutPageButton: {
      fontFamily,
      fontSize: '0.8125rem',
      fontWeight: 500,
    },
    primaryButton: {
      fontFamily,
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    payButton: {
      fontFamily,
      fontSize: '1.375rem',
      fontWeight: 500,
    },
    errorSearchButton: {
      fontFamily,
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    newOrderButton: {
      fontFamily,
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    tipsAmount: {
      fontFamily,
      fontSize: '2rem',
      lineHeight: '2.375rem',
      fontWeight: 500,
    },
    ratingLabel: {
      fontFamily,
      fontSize: '0.9375rem',
      lineHeight: '1.125rem',
    },
    modalOptions: {
      fontFamily,
      fontSize: '1.375rem',
      lineHeight: 'normal',
    },
    modalTitle: {
      fontFamily,
      fontSize: '1.875rem',
      lineHeight: 'normal',
    },
    modalTitleMedium: {
      fontFamily,
      fontSize: '1.875rem',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    buttonText: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: 'normal',
    },
    menuItems: {
      fontFamily,
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    productsCategoryTitle: {
      fontFamily,
      fontSize: '1.5rem',
    },
    productsCategoryTitleMedium: {
      fontFamily,
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    productTitle: {
      fontFamily,
      fontSize: '1.125rem',
    },
    productDescription: {
      fontFamily,
      fontSize: '0.75rem',
    },
    productPrice: {
      fontFamily,
      fontSize: '1rem',
    },
    productOldPrice: {
      fontFamily,
      fontSize: '1rem',
    },
    heroTitle: {
      fontFamily,
      fontSize: '3.125rem',
    },
    cartButton: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: '0.9375rem',
    },
    cartItemsNumber: {
      fontFamily,
      fontSize: '0.75rem',
      lineHeight: '0.9375rem',
    },
    productScreenTitle: {
      fontFamily,
      fontSize: '1.75rem',
    },
    productScreenTitleBold: {
      fontFamily,
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    productScreenDescription: {
      fontFamily,
      fontSize: '1.25rem',
    },
    productOptionsTitle: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: '0.9375rem',
    },
    productOptions: {
      fontFamily,
      fontSize: '0.9375rem',
      lineHeight: '1.125rem',
    },
    errorTitle: {
      fontFamily,
      fontSize: '1.5rem',
      lineHeight: '2.1875rem',
    },
    errorDescription: {
      fontFamily,
      fontSize: '0.9375rem',
    },
    errorButtons: {
      fontFamily,
      fontSize: '1rem',
      lineHeight: '1.40625rem',
    },
    screenTitle: {
      fontFamily,
      fontSize: '1.875rem',
    },
    checkoutTitle: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: '1.125rem',
    },
    placeholder: {
      fontFamily,
      fontSize: '1rem',
      lineHeight: '1.40625rem',
    },
    checkoutProductTitle: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: '1.125rem',
    },
    checkoutProductDescription: {
      fontFamily,
      fontSize: '0.9375rem',
      lineHeight: '1.40625rem',
    },
    payButtons: {
      fontFamily,
      fontSize: '1.125rem',
    },
    orderTitle: {
      fontFamily,
      fontSize: '1.5625rem',
    },
    orderDescription: {
      fontFamily,
      fontSize: '0.9375rem',
    },
    orderNumber: {
      fontFamily,
      fontSize: '2rem',
    },
    orderSmallTitle: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: '1.125rem',
    },
    orderTotalPrice: {
      fontFamily,
      fontSize: '1.125rem',
      lineHeight: '1.6875rem',
    },
    mainTitleDesktop: {
      fontFamily,
      fontSize: '3.75rem',
      lineHeight: 'normal',
      fontWeight: 400,
    },
    mainTitleMobile: {
      fontFamily,
      fontSize: '2.5rem',
      lineHeight: 'normal',
      fontWeight: 400,
    },
    descriptionTextDesktop: {
      fontFamily,
      fontSize: '1.5rem',
      lineHeight: '2.1875rem',
      fontWeight: 400,
    },
    descriptionTextMobile: {
      fontFamily,
      fontSize: '1rem',
      lineHeight: 'normal',
      fontWeight: 400,
    },
  }
}

const colors = (customColors: Partial<DefaultTheme['colors']>): DefaultTheme['colors'] => {
  return {
    primaryText: '#000000',
    alternativePrimaryText: '#FFFFFF',
    secondaryText: '#7F7F7F',
    error: '#E43434',
    selectedOption: '#483AF4',
    item1: '#F9BA42',
    item2: '#483AF4',
    item3: '#000000',
    item4: '#FFFFFF',
    whiteItem: '#FFFFFF',
    primaryItem: '#F9BA42',
    buttons: '#DADADA',
    black: '#000000',
    mainColor: '#F9BA42',
    background: '#FFFFFF',
    ...customColors,
  }
}

export const sizes: DefaultTheme['sizes'] = {
  categoriesListContainerHeight: 55,
  maxPageContainerWidth: 1024,
  optionsListRowGap: 17,
  cartButtonSize: 112,
  iconButtonSize: 42,
  headerHeight: 54,
  aspectRatio: 16 / 9,
  defaultImgSize: 860,
  categoriesListItemImgSize: 52,
  contentContainerWidth: 1204,
}

export const queries: DefaultTheme['queries'] = {
  mobile: '(max-width: 991.98px)',
  desktop: '(min-width: 992px)',
}

export const useTheme = (): {theme: DefaultTheme; loading: boolean} => {
  const [primaryFontFamily, setPrimaryFontFamily] = useState<string>(DEFAULT_FONT_FAMILY)
  const [secondaryFontFamily, setSecondaryFontFamily] = useState<string>(DEFAULT_FONT_FAMILY)
  const [loading, setLoading] = useState<boolean>(false)

  const websiteData = useSelector((state: RootState) => state.website.websiteData)

  const customColors = useMemo(() => {
    const colors: Partial<DefaultTheme['colors']> = {}

    if (!IS_STAGING) {
      return colors
    }

    if (websiteData?.primaryTextColor) {
      colors.primaryText = websiteData.primaryTextColor
    }

    if (websiteData?.altPrimaryTextColor) {
      colors.alternativePrimaryText = websiteData.altPrimaryTextColor
    }

    if (websiteData?.secondaryTextColor) {
      colors.secondaryText = websiteData.secondaryTextColor
    }

    if (websiteData?.errorTextColor) {
      colors.error = websiteData.errorTextColor
    }

    if (websiteData?.selectedOptionTextColor) {
      colors.selectedOption = websiteData.selectedOptionTextColor
    }

    if (websiteData?.firstItemColor) {
      colors.item1 = websiteData.firstItemColor
      colors.primaryItem = websiteData.firstItemColor
      colors.mainColor = websiteData.firstItemColor
    }

    if (websiteData?.secondItemColor) {
      colors.item2 = websiteData.secondItemColor
    }

    if (websiteData?.thirdItemColor) {
      colors.item3 = websiteData.thirdItemColor
      colors.black = websiteData.thirdItemColor
    }

    if (websiteData?.fourthItemColor) {
      colors.item4 = websiteData.fourthItemColor
      colors.whiteItem = websiteData.fourthItemColor
    }

    if (websiteData?.backgroundColor) {
      colors.background = websiteData.backgroundColor
    }

    return colors
  }, [
    websiteData?.altPrimaryTextColor,
    websiteData?.errorTextColor,
    websiteData?.firstItemColor,
    websiteData?.fourthItemColor,
    websiteData?.primaryTextColor,
    websiteData?.secondItemColor,
    websiteData?.secondaryTextColor,
    websiteData?.selectedOptionTextColor,
    websiteData?.thirdItemColor,
    websiteData?.backgroundColor,
  ])

  const loadFonts = useCallback(async () => {
    const primaryFont = websiteData?.primaryFont
    const secondaryFont = websiteData?.secondaryFont

    if (!primaryFont && !secondaryFont) {
      return
    }

    setLoading(true)

    if (primaryFont) {
      const familyName = await loadFont(primaryFont)
      if (familyName) {
        setPrimaryFontFamily(familyName)
      }
    }

    if (secondaryFont) {
      const familyName = await loadFont(secondaryFont)
      if (familyName) {
        setSecondaryFontFamily(familyName)
      }
    }

    setLoading(false)
  }, [websiteData?.primaryFont, websiteData?.secondaryFont])

  useEffect(() => {
    loadFonts()
  }, [loadFonts])

  return {
    theme: {
      typography: typography(primaryFontFamily),
      colors: colors(customColors),
      queries,
      sizes,
      fontFamilies: {
        primary: primaryFontFamily,
        secondary: secondaryFontFamily,
      },
    },
    loading,
  }
}
