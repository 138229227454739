import {MouseEvent, memo} from 'react'
import styled from 'styled-components'

import AddIcon from 'src/assets/icons/add.svg?react'

interface ProductsListItemAddButtonProps {
  onAdd: () => void
}

const ProductsListItemAddButtonContainer = styled.button`
  border: none;
  cursor: pointer;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.item1};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const ProductsListItemAddButton = (props: ProductsListItemAddButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation()
    props.onAdd()
  }

  return (
    <ProductsListItemAddButtonContainer onClick={handleClick}>
      <AddIcon />
    </ProductsListItemAddButtonContainer>
  )
}

export default memo(ProductsListItemAddButton)
