import styled, {AnyStyledComponent} from 'styled-components'

import IconButton from 'src/components/IconButton'
import Text from 'src/components/Text'

interface LocationButtonProps {
  title?: string
  onClick?: () => void
}

const ButtonContainer = styled.div`
  background-color: ${({theme}) => `${theme.colors.whiteItem}D9`};
  border-radius: ${({theme}) => theme.sizes.iconButtonSize / 2}px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`

const IconButtonContainer = styled.div`
  pointer-events: none;
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'menuItems',
  style: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0 0.75rem',
  },
})``

const LocationButton = ({title, onClick}: LocationButtonProps) => {
  return (
    <ButtonContainer onClick={onClick}>
      <IconButtonContainer>
        <IconButton name="location" variant="gray" />
      </IconButtonContainer>

      {!!title && <Title>{title}</Title>}
    </ButtonContainer>
  )
}

export default LocationButton
