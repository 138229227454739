import styled, {AnyStyledComponent} from 'styled-components'
import {useEffect, useRef, useState} from 'react'

import InfoIcon from 'src/assets/icons/info.svg?react'
import CustomScroll from 'src/components/CustomScroll'
import {useClickOutside, useMediaQuery} from 'src/utilities/hooks'
import Text from 'src/components/Text'

interface PopoverProps {
  title?: string
  titleLower?: string
}

const MainContainer = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  position: relative;
  border-radius: 50%;
  box-shadow: 0 2px 3px 0 #493bef29;

  @media ${({theme}) => theme.queries.mobile} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const Button = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  width: inherit;
  height: inherit;

  :enabled {
    cursor: pointer;
  }
`

const Icon = styled(InfoIcon as AnyStyledComponent)`
  width: 100%;
  height: 100%;
`

const PopoverContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 1.25rem);
  right: -1.25rem;
  z-index: 1;
  width: 24rem;
  background-color: ${({theme}) => theme.colors.whiteItem};
  border-radius: 1rem;
  box-shadow: 0 2px 30px rgba(108, 108, 108, 0.5);
  max-height: 12rem;

  @media ${({theme}) => theme.queries.mobile} {
    bottom: calc(100% + 1rem);
    width: 19rem;
  }
`

const PopoverContentContainer = styled(CustomScroll as AnyStyledComponent)`
  max-height: inherit;
  padding: 1.5rem;
  box-sizing: border-box;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 1rem;
  }
`

const Box = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${({theme}) => theme.colors.whiteItem};
  transform: rotate(45deg);
  position: absolute;
  bottom: -0.625rem;
  right: 1.25rem;
  border-radius: 0.25rem;
  overflow: hidden;
  z-index: -1;

  @media ${({theme}) => theme.queries.mobile} {
    width: 1.25rem;
    height: 1.25rem;
    bottom: -0.5rem;
  }
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'errorButtons',
})`
  span {
    font-weight: 700;
  }
`

const Popover = ({title, titleLower}: PopoverProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const {isMobile} = useMediaQuery()

  const mainContainerRef = useRef<HTMLDivElement>(null)

  const show = () => {
    setVisible(true)
  }

  const hide = () => {
    setVisible(false)
  }

  const handleClick = () => {
    if (!isMobile) {
      return
    }

    if (visible) {
      hide()
      return
    }

    show()
  }

  const handleMouseEnter = () => {
    if (isMobile) {
      return
    }

    show()
  }

  const handleMouseLeave = () => {
    if (isMobile) {
      return
    }

    hide()
  }

  useClickOutside(mainContainerRef.current, () => {
    if (!isMobile) {
      return
    }

    hide()
  })

  useEffect(() => {
    hide()
  }, [isMobile])

  return (
    <MainContainer ref={mainContainerRef}>
      <Button onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Icon />
      </Button>

      {visible && (
        <PopoverContainer>
          <PopoverContentContainer paddingX={isMobile ? '0.25rem' : '0.5rem'} paddingY={isMobile ? '1rem' : '1.5rem'}>
            <Title>
              {title} {titleLower ? <span>{titleLower}</span> : ''}
            </Title>
          </PopoverContentContainer>

          <Box />
        </PopoverContainer>
      )}
    </MainContainer>
  )
}

export default Popover
