import {ComponentPropsWithoutRef} from 'react'
import styled from 'styled-components'

import SelectButton from 'src/components/SelectButton'
import LocationIcon from 'src/assets/icons/location.svg'

interface NearestRestaurantButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: 'primary' | 'secondary'
}

const SelectButtonStyled = styled(SelectButton)<NearestRestaurantButtonProps>`
  ${({variant = 'primary', theme}) => {
    switch (variant) {
      case 'primary':
        return `background-color: ${theme.colors.buttons};`
      case 'secondary':
        return `background-color: ${theme.colors.primaryItem};`
      default:
        return
    }
  }}
`

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const LocationImg = styled.img.attrs({
  src: LocationIcon,
  alt: 'location',
})`
  width: 2.125rem;
  height: 2.125rem;
  object-fit: contain;

  @media ${({theme}) => theme.queries.mobile} {
    width: 1.625rem;
    height: 1.625rem;
  }
`

const NearestRestaurantButton = ({children, ...props}: NearestRestaurantButtonProps) => {
  return (
    <SelectButtonStyled {...props}>
      <ContentContainer>
        <LocationImg />

        {children}
      </ContentContainer>
    </SelectButtonStyled>
  )
}

export default NearestRestaurantButton
