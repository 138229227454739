import {Fragment, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import styled, {AnyStyledComponent} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {produce} from 'immer'
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react'

import {Dispatch, RootState} from 'src/utilities/store'
import {OptionSetContract, OptionSetType, ProductContract} from 'src/types/api'
import {findDuplicatedCartItem, getCartItemWithPreselectedOptions, mediaUrl} from 'src/utilities/functions'
import IconButton from 'src/components/IconButton'
import Text from 'src/components/Text'
import OptionsList from './components/OptionsList'
import {CartItemOption, CartItem, selectCart, CartItemOptionSet} from 'src/models/cart'
import {sizes as themeSizes} from 'src/utilities/theme'
import Footer from 'src/components/Footer'
import {selectIsLoyaltyCardType, selectProduct, selectProductTags} from 'src/models/catalog'
import {useMediaQuery, useWIndowSizes} from 'src/utilities/hooks'
import Modal from 'src/components/Modal'
import CollapsibleContainer from './components/CollapsibleContainer'
import Header from 'src/components/Header'
import NewModal from 'src/components/NewModal'
import CustomScroll from 'src/components/CustomScroll'
import LoyaltyCardEmail, {LoyaltyCardEmailRef} from './components/LoyaltyCardEmail'
import {selectOnlyMenu} from 'src/models/profile'
import InfoButton from './components/InfoButton'
import AllergensModal from './components/AllergensModal'
import Allergens from 'src/components/Allergens'

interface LocationState {
  cartId: string
}

interface MainContainerProps {
  height: number
  hasOptionSets: boolean
}

interface InfoContainerProps {
  hasShadow?: boolean
}

interface ContentContainerProps {
  hasOptionSets: boolean
}

interface HeaderStyledProps {
  hasOptionSets: boolean
}

interface DesktopMainContainerProps {
  hasOptionSets: boolean
}

interface DesktopContentContainerProps {
  hasOptionSets: boolean
}

interface AllergensContainerProps {
  hasOptionSets: boolean
}

const MainContainer = styled.div<MainContainerProps>`
  overflow-y: auto;
  height: ${({height}) => height}px;
  display: grid;
  grid-template-rows: 1fr;
  align-items: ${({hasOptionSets}) => (hasOptionSets ? 'stretch' : 'end')};
`

const ContentContainer = styled.div<ContentContainerProps>`
  background-color: ${({theme}) => theme.colors.background};
  padding-bottom: calc(3.625rem + ${({theme}) => theme.sizes.cartButtonSize / 2}px);
  border-radius: ${({hasOptionSets}) => (hasOptionSets ? 0 : '1.25rem 1.25rem 0 0')};
`

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`

const HeaderContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  align-items: center;
  height: ${({theme}) => theme.sizes.headerHeight}px;
  background-color: ${({theme}) => theme.colors.background};
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.5);
  padding: 0 0.5rem;
`

const HeaderContentActionContainer = styled.div`
  align-self: start;
  padding-top: 0.375rem;
`

const InfoContainer = styled.div<InfoContainerProps>`
  padding: 1.5rem;
  box-shadow: ${({hasShadow}) => (hasShadow ? '0px -2px 14px rgba(0, 0, 0, 0.5)' : 'none')};
  z-index: 1;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 1.063rem 0.75rem;
  }
`

const DescriptionContainer = styled.div`
  padding-top: 1.25rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding-top: 0.375rem;
  }
`

const OptionsListContainer = styled.div`
  display: grid;
  row-gap: ${({theme}) => theme.sizes.optionsListRowGap}px;
  padding: 1.5rem 1.5rem 5rem 1.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 1.25rem 0.75rem;
  }
`

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: ${({theme}) => theme.sizes.maxPageContainerWidth}px;
  z-index: 1;
`

const DesktopMainContainer = styled.div<DesktopMainContainerProps>`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  height: 55.938rem;
  max-height: calc(100vh - 6rem);

  ${({hasOptionSets}) =>
    hasOptionSets &&
    `
    grid-template-rows: auto;
    grid-template-columns: 0.48fr 0.52fr;
  `}
`

const DesktopProductImgContainer = styled.div`
  overflow: hidden;
  position: relative;
`

const DesktopProductImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const DesktopContentContainer = styled.div<DesktopContentContainerProps>`
  ${({hasOptionSets}) =>
    hasOptionSets &&
    `
    display: grid;
    grid-template-rows: auto 1fr auto;
    overflow: hidden;
  `}
`

const DesktopOptionsListContainer = styled(CustomScroll as unknown as AnyStyledComponent)`
  position: relative;
`

const HeaderStyled = styled(Header as unknown as AnyStyledComponent).attrs<HeaderStyledProps>(({hasOptionSets}) => ({
  bgImgStyle: {borderRadius: hasOptionSets ? 0 : '1.25rem 1.25rem 0 0'},
}))<HeaderStyledProps>``

const LoyaltyCardEmailContainer = styled.div`
  padding-top: 0.75rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding-top: 1rem;
  }
`

const Description = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'productPageDescription',
})`
  white-space: pre-wrap;
`

const HeaderTopComponentContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1;
`

const AllergensContainer = styled.div<AllergensContainerProps>`
  padding: ${({hasOptionSets}) => (hasOptionSets ? '0.6875rem 1.25rem' : '0.9375rem 1.9375rem 0.9375rem 1.5rem')};
  display: flex;
  column-gap: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: end;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 0.875rem 0.75rem;
  }
`

const TagsContainer = styled.div`
  flex: 1;
`

const Product = () => {
  const [cartItem, setCartItem] = useState<CartItem | null>(null)
  const [requiredOptionSetIds, setRequiredOptionSetIds] = useState<{[optionSetId: string]: number}>({})
  const [allergensModalVisible, setAllergensModalVisible] = useState<boolean>(false)

  const params = useParams<{tabletId: string; productId: string}>()
  const location = useLocation()
  const locationState = location.state as LocationState | null
  const navigate = useNavigate()

  const cart = useSelector(selectCart)
  const product = useSelector((state: RootState) => selectProduct(state, params.productId!))
  const user = useSelector((state: RootState) => state.profile.user)
  const isLoyaltyCardType = useSelector((state: RootState) => selectIsLoyaltyCardType(state, params?.productId!))
  const onlyMenu = useSelector(selectOnlyMenu)
  const productTags = useSelector((state: RootState) => selectProductTags(state, product?.id))

  const {t} = useTranslation()

  const dispatch = useDispatch<Dispatch>()

  const sizes = useWIndowSizes()

  const {isMobile} = useMediaQuery()

  const optionsListRefs = useRef<{[optionSetId: string]: HTMLDivElement | null}>({})
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const desktopOptionsListContainerRef = useRef<OverlayScrollbarsComponentRef>(null)
  const loyaltyCardEmailRef = useRef<LoyaltyCardEmailRef>(null)

  const cartId = useMemo(() => locationState?.cartId, [locationState?.cartId])

  const headerImgHeight = useMemo(() => {
    const imgHeight = sizes.width
    const halfWindowHeight = window.innerHeight / 2
    if (imgHeight > halfWindowHeight) {
      return halfWindowHeight
    }
    return imgHeight
  }, [sizes.width])

  const headerImgUrl = useMemo(() => mediaUrl(product?.images?.[0]), [product?.images])

  const totalPrice = useMemo(() => {
    if (!cartItem) {
      return 0
    }

    const productDiscountAmount = cartItem.product.discountAmount ?? 0
    const productPrice = cartItem.product.price! + (onlyMenu ? productDiscountAmount : 0)
    const productCount = cartItem.count

    const optionsSetTotalSum = Object.values(cartItem.optionSets).reduce((optionSetsSum, optionSet) => {
      const optionsTotalSum = Object.values(optionSet.options).reduce((optionsSum, option) => {
        const optionPrice = option.option.price!
        const optionCount = option.count

        return (optionsSum += optionPrice * optionCount)
      }, 0)

      return (optionSetsSum += optionsTotalSum)
    }, 0)

    const childOptionsSetTotalSum = Object.values(cartItem?.childOptionSets ?? {}).reduce(
      (parentOptionSetsSum, parentOptionSet) => {
        const childOptionSetsTotalSum = Object.values(parentOptionSet ?? {}).reduce(
          (childOptionSetsSum, childOptionSet) => {
            const optionsTotalSum = Object.values(childOptionSet?.options ?? {}).reduce((optionsSum, option) => {
              const optionPrice = option?.option?.price ?? 0
              const optionCount = option.count

              return (optionsSum += optionPrice * optionCount)
            }, 0)

            return (childOptionSetsSum += optionsTotalSum)
          },
          0,
        )

        return (parentOptionSetsSum += childOptionSetsTotalSum)
      },
      0,
    )

    return (productPrice + optionsSetTotalSum + childOptionsSetTotalSum) * productCount
  }, [cartItem, onlyMenu])

  const hasOptionSets = useMemo(() => !!product?.optionSets?.length, [product?.optionSets?.length])

  const toggleAllergensModal = () => {
    setAllergensModalVisible((prevAllergensModalVisible) => !prevAllergensModalVisible)
  }

  const setInitialCartProduct = useCallback(() => {
    if (cartItem || !product) {
      return
    }

    if (cartId) {
      setCartItem(cart.items[cartId])
    } else {
      setCartItem(getCartItemWithPreselectedOptions(product))
    }
  }, [cart.items, cartId, cartItem, product])

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const optionsCount = useCallback((options?: CartItemOption) => {
    return Object.values(options ?? {}).reduce((count, option) => (count = count + option.count), 0)
  }, [])

  const checkRequiredOptionSets = useCallback(() => {
    if (!cartItem) {
      return
    }

    const requiredIds = cartItem.product.optionSets!.reduce<{[optionSetId: string]: number}>((ids, optionSet) => {
      const minSelections = optionSet.minSelections!
      if (minSelections === 0) {
        return ids
      }
      const optionSetId = optionSet.id!
      const cartOptionsCount = optionsCount(cartItem?.optionSets?.[optionSetId]?.options)
      if (cartOptionsCount >= minSelections) {
        return ids
      }
      ids[optionSetId] = minSelections
      return ids
    }, {})

    const requiredChildIds = cartItem.product.optionSets!.reduce<{[optionSetId: string]: number}>(
      (ids, parentOptionSet) => {
        if (!Object.keys(cartItem?.optionSets?.[parentOptionSet?.id!]?.options ?? {}).length) {
          return ids
        }

        parentOptionSet.options?.forEach((option) => {
          if (!cartItem?.optionSets?.[parentOptionSet?.id!]?.options?.[option?.id!]) {
            return ids
          }
          option.optionSets?.forEach((childOptionSet) => {
            const minSelections = childOptionSet.minSelections!
            if (minSelections === 0) {
              return ids
            }
            const optionSetId = childOptionSet.id!
            const cartOptionsCount = optionsCount(
              cartItem?.childOptionSets?.[parentOptionSet.id!]?.[optionSetId]?.options,
            )
            if (cartOptionsCount >= minSelections) {
              return ids
            }
            ids[optionSetId] = minSelections
            return ids
          })
        })
        return ids
      },
      {},
    )

    const ids = {...requiredIds, ...requiredChildIds}

    setRequiredOptionSetIds(ids)

    return ids
  }, [cartItem, optionsCount])

  const handleOptionAdd = useCallback(
    (data: {optionSet: OptionSetContract; option: ProductContract}) => {
      const productId = product?.id
      if (!productId || !cartItem) {
        return
      }

      const optionSetId = data.optionSet.id!
      const optionId = data.option.id!

      const options = cartItem?.optionSets?.[optionSetId]?.options
      const foundOption = options?.[optionId]
      const reachedMaxSelections = optionsCount(options) === data.optionSet.maxSelections

      let updatedCartItem: CartItem | null = null

      switch (data.optionSet.type) {
        case OptionSetType.Single:
          updatedCartItem = produce(cartItem, (draft) => {
            draft.optionSets[optionSetId] = {
              optionSet: data.optionSet,
              options: {
                [optionId]: {
                  option: data.option,
                  count: 1,
                },
              },
            }
          })
          break
        case OptionSetType.Multi:
          if (reachedMaxSelections && !foundOption) {
            return
          }

          if (foundOption) {
            updatedCartItem = produce(cartItem, (draft) => {
              delete draft.optionSets[optionSetId].options[optionId]

              delete draft.childOptionSets[optionSetId]
            })
          } else {
            updatedCartItem = produce(cartItem, (draft) => {
              draft.optionSets[optionSetId] = {
                optionSet: data.optionSet,
                options: {
                  ...draft.optionSets?.[optionSetId]?.options,
                  [optionId]: {
                    option: data.option,
                    count: 1,
                  },
                },
              }

              const childOptionSets = (data.option.optionSets ?? []).reduce<CartItemOptionSet>(
                (obj, childOptionSet) => {
                  const childOptionSetId = childOptionSet.id
                  if (!childOptionSetId) {
                    return obj
                  }

                  childOptionSet.options?.forEach((childOption) => {
                    const childOptionId = childOption.id
                    const childOptionCount = childOption.count

                    if (!childOptionId || !childOptionCount) {
                      return
                    }

                    obj[childOptionSetId] = {
                      optionSet: childOptionSet,
                      options: {
                        ...obj[childOptionSetId]?.options,
                        [childOptionId]: {
                          option: childOption,
                          count: childOptionCount,
                        },
                      },
                    }
                  })

                  return obj
                },
                {},
              )

              if (Object.keys(childOptionSets).length) {
                draft.childOptionSets[optionSetId] = childOptionSets
              }
            })
          }
          break
        case OptionSetType.Extra:
          if (reachedMaxSelections) {
            return
          }

          updatedCartItem = produce(cartItem, (draft) => {
            draft.optionSets[optionSetId] = {
              optionSet: data.optionSet,
              options: {
                ...draft.optionSets?.[optionSetId]?.options,
                [optionId]: {
                  option: data.option,
                  count: (draft.optionSets?.[optionSetId]?.options?.[optionId]?.count ?? 0) + 1,
                },
              },
            }
          })
          break
        default:
          break
      }

      setCartItem(updatedCartItem)
    },
    [cartItem, optionsCount, product?.id],
  )

  const handleChildOptionAdd = useCallback(
    (data: {parentOptionSet: OptionSetContract; optionSet: OptionSetContract; option: ProductContract}) => {
      const productId = product?.id
      if (!productId || !cartItem) {
        return
      }

      const parentOptionSetId = data?.parentOptionSet?.id!
      const optionSetId = data.optionSet.id!
      const optionId = data.option.id!

      const options = cartItem?.childOptionSets?.[parentOptionSetId]?.[optionSetId]?.options
      const foundOption = options?.[optionId]
      const reachedMaxSelections = optionsCount(options) === data.optionSet.maxSelections

      let updatedCartItem: CartItem | null = null

      switch (data.optionSet.type) {
        case OptionSetType.Single:
          updatedCartItem = produce(cartItem, (draft) => {
            draft.childOptionSets[parentOptionSetId] = {
              ...draft.childOptionSets[parentOptionSetId],
              [optionSetId]: {
                optionSet: data.optionSet,
                options: {
                  [optionId]: {
                    option: data.option,
                    count: 1,
                  },
                },
              },
            }
          })
          break
        case OptionSetType.Multi:
          if (reachedMaxSelections && !foundOption) {
            return
          }

          if (foundOption) {
            updatedCartItem = produce(cartItem, (draft) => {
              delete draft.childOptionSets[parentOptionSetId][optionSetId].options[optionId]
            })
          } else {
            updatedCartItem = produce(cartItem, (draft) => {
              draft.childOptionSets[parentOptionSetId] = {
                ...draft.childOptionSets[parentOptionSetId],
                [optionSetId]: {
                  optionSet: data.optionSet,
                  options: {
                    ...draft.childOptionSets?.[parentOptionSetId]?.[optionSetId]?.options,
                    [optionId]: {
                      option: data.option,
                      count: 1,
                    },
                  },
                },
              }
            })
          }
          break
        case OptionSetType.Extra:
          if (reachedMaxSelections) {
            return
          }

          updatedCartItem = produce(cartItem, (draft) => {
            draft.childOptionSets[parentOptionSetId] = {
              ...draft.childOptionSets[parentOptionSetId],
              [optionSetId]: {
                optionSet: data.optionSet,
                options: {
                  ...draft.childOptionSets?.[parentOptionSetId]?.[optionSetId]?.options,
                  [optionId]: {
                    option: data.option,
                    count:
                      (draft.childOptionSets?.[parentOptionSetId]?.[optionSetId]?.options?.[optionId]?.count ?? 0) + 1,
                  },
                },
              },
            }
          })
          break
        default:
          break
      }

      setCartItem(updatedCartItem)
    },
    [cartItem, optionsCount, product?.id],
  )

  const handleOptionRemove = useCallback(
    (data: {optionSetId: string; optionId: string}) => {
      if (!cartItem) {
        return
      }
      let updatedCartItem: CartItem | null = null
      const foundOption = cartItem.optionSets?.[data.optionSetId]?.options?.[data.optionId]
      if (foundOption && foundOption.count > 1) {
        updatedCartItem = produce(cartItem, (draft) => {
          draft.optionSets[data.optionSetId] = {
            ...draft.optionSets?.[data.optionSetId],
            options: {
              ...draft.optionSets?.[data.optionSetId]?.options,
              [data.optionId]: {
                ...foundOption,
                count: foundOption.count - 1,
              },
            },
          }
        })
      } else {
        updatedCartItem = produce(cartItem, (draft) => {
          delete draft.optionSets[data.optionSetId].options[data.optionId]
        })
      }
      setCartItem(updatedCartItem)
    },
    [cartItem],
  )

  const handleChildOptionRemove = useCallback(
    (data: {parentOptionSetId: string; optionSetId: string; optionId: string}) => {
      if (!cartItem) {
        return
      }
      let updatedCartItem: CartItem | null = null
      const foundOption =
        cartItem.childOptionSets?.[data.parentOptionSetId]?.[data.optionSetId]?.options?.[data.optionId]
      if (foundOption && foundOption.count > 1) {
        updatedCartItem = produce(cartItem, (draft) => {
          draft.childOptionSets[data.parentOptionSetId] = {
            ...draft.childOptionSets[data.parentOptionSetId],
            [data.optionSetId]: {
              ...draft.childOptionSets?.[data.parentOptionSetId]?.[data.optionSetId],
              options: {
                ...draft.childOptionSets?.[data.parentOptionSetId]?.[data.optionSetId]?.options,
                [data.optionId]: {
                  ...foundOption,
                  count: foundOption.count - 1,
                },
              },
            },
          }
        })
      } else {
        updatedCartItem = produce(cartItem, (draft) => {
          delete draft.childOptionSets[data.parentOptionSetId][data.optionSetId].options[data.optionId]
        })
      }
      setCartItem(updatedCartItem)
    },
    [cartItem],
  )

  const handleCounterAdd = useCallback(() => {
    if (!cartItem) {
      return
    }
    setCartItem({...cartItem, count: cartItem.count + 1})
  }, [cartItem])

  const handleCounterRemove = useCallback(() => {
    if (!cartItem || cartItem.count === 1) {
      return
    }
    setCartItem({...cartItem, count: cartItem.count - 1})
  }, [cartItem])

  const handleCartClick = useCallback(() => {
    if (!cartItem) {
      return
    }

    const cartItemData: CartItem = {...cartItem}

    const loyaltyEmail = loyaltyCardEmailRef.current?.submit()
    if (loyaltyEmail) {
      cartItemData.loyaltyEmail = loyaltyEmail
    }

    const requiredOptionSetKeys = Object.keys(checkRequiredOptionSets() ?? {})
    if (requiredOptionSetKeys.length) {
      const optionSetOffsetTop = optionsListRefs.current[requiredOptionSetKeys[0]]?.offsetTop ?? 0

      const scrollTop = isMobile
        ? optionSetOffsetTop - themeSizes.headerHeight - themeSizes.optionsListRowGap
        : optionSetOffsetTop - themeSizes.optionsListRowGap

      if (isMobile) {
        mainContainerRef.current?.scroll({top: scrollTop, behavior: 'smooth'})
        return
      }

      desktopOptionsListContainerRef.current
        ?.osInstance()
        ?.elements()
        .viewport.scroll({top: scrollTop, behavior: 'smooth'})

      return
    }

    if (isLoyaltyCardType && !loyaltyEmail) {
      return
    }

    loyaltyCardEmailRef.current?.reset()

    const foundDuplicatedCartItem = findDuplicatedCartItem(cart.items, cartItemData)
    if (foundDuplicatedCartItem) {
      const id = foundDuplicatedCartItem.id
      const count = cartItemData.count

      if (cartId) {
        dispatch.cart.updateProductCount({id, count})
      } else {
        dispatch.cart.increaseProductCountBy({id, count})
      }

      navigateBack()

      return
    }

    dispatch.cart.addToCart(cartItemData)

    navigateBack()
  }, [cart.items, cartId, cartItem, checkRequiredOptionSets, dispatch.cart, isLoyaltyCardType, isMobile, navigateBack])

  const handleContentClick = useCallback((e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation()
  }, [])

  const handleAllergensMoreClick = () => {
    if (!user?.allergensUrl) {
      return
    }

    window.open(user.allergensUrl)
  }

  const renderAllergens = () => {
    if (!user?.allergensUrl && !productTags.length) {
      return null
    }

    return (
      <AllergensContainer hasOptionSets={hasOptionSets}>
        <TagsContainer>{!!productTags.length && <Allergens allergens={productTags} />}</TagsContainer>

        {!!user?.allergensUrl && (
          <InfoButton onClick={toggleAllergensModal}>{t('pages.product.infoButtonText')}</InfoButton>
        )}
      </AllergensContainer>
    )
  }

  const renderAllergensModal = () => {
    if (!user?.allergensUrl) {
      return null
    }

    return (
      <AllergensModal
        isOpen={allergensModalVisible}
        onCloseClick={toggleAllergensModal}
        onMoreClick={handleAllergensMoreClick}
      />
    )
  }

  useEffect(() => {
    if (isMobile) {
      mainContainerRef.current?.scroll({top: 0})
      return
    }

    desktopOptionsListContainerRef.current?.osInstance()?.elements().viewport.scroll({top: 0})
  }, [isMobile])

  useEffect(() => {
    setInitialCartProduct()
  }, [setInitialCartProduct])

  if (isMobile) {
    return (
      <>
        <Modal isOpen={true}>
          <CollapsibleContainer />
          <MainContainer
            ref={mainContainerRef}
            height={sizes.height}
            hasOptionSets={hasOptionSets}
            onClick={navigateBack}
          >
            <ContentContainer hasOptionSets={hasOptionSets} onClick={handleContentClick}>
              <HeaderContainer>
                <HeaderStyled
                  imgsUrl={[headerImgUrl]}
                  imgHeight={headerImgHeight}
                  TopComponent={
                    <HeaderTopComponentContainer>
                      <IconButton name="arrow-back" onClick={navigateBack} />
                    </HeaderTopComponentContainer>
                  }
                  BgContentComponent={renderAllergens()}
                  HeaderContentComponent={
                    <HeaderContentContainer>
                      <HeaderContentActionContainer>
                        <IconButton name="arrow-back" onClick={navigateBack} />
                      </HeaderContentActionContainer>
                      <Text type="productPageTitle">{product?.title}</Text>
                    </HeaderContentContainer>
                  }
                  scrollRef={mainContainerRef}
                  hasOptionSets={hasOptionSets}
                />
              </HeaderContainer>
              <InfoContainer hasShadow={hasOptionSets}>
                <Text type="productPageTitle">{product?.title}</Text>
                {!!product?.description && (
                  <DescriptionContainer>
                    <Description>{product.description}</Description>
                  </DescriptionContainer>
                )}
              </InfoContainer>
              {hasOptionSets && (
                <OptionsListContainer>
                  {product?.optionSets?.map((parentOptionSet) => (
                    <Fragment key={parentOptionSet.id}>
                      <OptionsList
                        ref={(itemRef) => (optionsListRefs.current[parentOptionSet.id!] = itemRef)}
                        cartItemProductId={cartItem?.product?.id}
                        hasError={!!requiredOptionSetIds[parentOptionSet.id!]}
                        optionSet={parentOptionSet}
                        cartItemOptionSets={cartItem?.optionSets}
                        onOptionAdd={handleOptionAdd}
                        onOptionRemove={handleOptionRemove}
                      />

                      {!!Object.values(cartItem?.optionSets?.[parentOptionSet?.id!]?.options ?? {}).length &&
                        Object.values(cartItem!.optionSets[parentOptionSet.id!].options).map((parentOption) =>
                          parentOption?.option?.optionSets?.map((childOptionSet) => (
                            <OptionsList
                              ref={(itemRef) => (optionsListRefs.current[childOptionSet?.id!] = itemRef)}
                              cartItemProductId={cartItem?.product?.id}
                              key={`${parentOption?.option?.id}-${childOptionSet?.id}`}
                              hasError={!!requiredOptionSetIds?.[childOptionSet?.id!]}
                              optionSet={childOptionSet}
                              cartItemOptionSets={cartItem?.childOptionSets?.[parentOptionSet?.id!]}
                              onOptionAdd={(data) => handleChildOptionAdd({...data, parentOptionSet})}
                              onOptionRemove={(data) =>
                                handleChildOptionRemove({...data, parentOptionSetId: parentOptionSet?.id!})
                              }
                            />
                          )),
                        )}
                    </Fragment>
                  ))}

                  {isLoyaltyCardType && (
                    <LoyaltyCardEmailContainer>
                      <LoyaltyCardEmail ref={loyaltyCardEmailRef} defaultEmail={cartItem?.loyaltyEmail} />
                    </LoyaltyCardEmailContainer>
                  )}
                </OptionsListContainer>
              )}
              <FooterContainer>
                <Footer
                  cartSum={totalPrice}
                  count={cartItem?.count}
                  intercomEnabled={user?.enableSupportChat}
                  addDisabled={isLoyaltyCardType}
                  hideLeftContent={onlyMenu}
                  cartButtonDisabled={onlyMenu}
                  CartContentComponent={
                    onlyMenu ? null : (
                      <Text type="productPageOptionName">
                        {t(`pages.product.cartButton${cartId ? 'Update' : 'Add'}Text`)}
                      </Text>
                    )
                  }
                  onCounterAdd={handleCounterAdd}
                  onCounterRemove={handleCounterRemove}
                  onCartClick={handleCartClick}
                />
              </FooterContainer>
            </ContentContainer>
          </MainContainer>
        </Modal>

        {renderAllergensModal()}
      </>
    )
  }

  return (
    <>
      <NewModal isOpen={true} width={hasOptionSets ? '82.563rem' : '40.125rem'} showCloseButton>
        <DesktopMainContainer hasOptionSets={hasOptionSets}>
          <DesktopProductImgContainer>
            <DesktopProductImg src={headerImgUrl} alt="product-img" />

            {renderAllergens()}
          </DesktopProductImgContainer>

          <DesktopContentContainer hasOptionSets={hasOptionSets}>
            <InfoContainer hasShadow={hasOptionSets}>
              <Text type="productPageTitle">{product?.title}</Text>

              {!!product?.description && (
                <DescriptionContainer>
                  <Description>{product.description}</Description>
                </DescriptionContainer>
              )}
            </InfoContainer>

            {hasOptionSets && (
              <DesktopOptionsListContainer ref={desktopOptionsListContainerRef} paddingX="0.5rem" paddingY="1.5rem">
                <OptionsListContainer>
                  {product?.optionSets?.map((parentOptionSet) => (
                    <Fragment key={parentOptionSet.id}>
                      <OptionsList
                        ref={(itemRef) => (optionsListRefs.current[parentOptionSet.id!] = itemRef)}
                        cartItemProductId={cartItem?.product?.id}
                        hasError={!!requiredOptionSetIds[parentOptionSet.id!]}
                        optionSet={parentOptionSet}
                        cartItemOptionSets={cartItem?.optionSets}
                        onOptionAdd={handleOptionAdd}
                        onOptionRemove={handleOptionRemove}
                      />

                      {!!Object.values(cartItem?.optionSets?.[parentOptionSet?.id!]?.options ?? {}).length &&
                        Object.values(cartItem!.optionSets[parentOptionSet.id!].options).map((parentOption) =>
                          parentOption?.option?.optionSets?.map((childOptionSet) => (
                            <OptionsList
                              ref={(itemRef) => (optionsListRefs.current[childOptionSet?.id!] = itemRef)}
                              cartItemProductId={cartItem?.product?.id}
                              key={`${parentOption?.option?.id}-${childOptionSet?.id}`}
                              hasError={!!requiredOptionSetIds?.[childOptionSet?.id!]}
                              optionSet={childOptionSet}
                              cartItemOptionSets={cartItem?.childOptionSets?.[parentOptionSet?.id!]}
                              onOptionAdd={(data) => handleChildOptionAdd({...data, parentOptionSet})}
                              onOptionRemove={(data) =>
                                handleChildOptionRemove({...data, parentOptionSetId: parentOptionSet?.id!})
                              }
                            />
                          )),
                        )}
                    </Fragment>
                  ))}

                  {isLoyaltyCardType && (
                    <LoyaltyCardEmailContainer>
                      <LoyaltyCardEmail ref={loyaltyCardEmailRef} defaultEmail={cartItem?.loyaltyEmail} />
                    </LoyaltyCardEmailContainer>
                  )}
                </OptionsListContainer>
              </DesktopOptionsListContainer>
            )}

            <Footer
              showMobileVersion
              hideBrandLogo
              cartSum={totalPrice}
              count={cartItem?.count}
              intercomEnabled={user?.enableSupportChat}
              addDisabled={isLoyaltyCardType}
              hideLeftContent={onlyMenu}
              cartButtonDisabled={onlyMenu}
              CartContentComponent={
                onlyMenu ? null : (
                  <Text type="productPageOptionName">
                    {t(`pages.product.cartButton${cartId ? 'Update' : 'Add'}Text`)}
                  </Text>
                )
              }
              onCounterAdd={handleCounterAdd}
              onCounterRemove={handleCounterRemove}
              onCartClick={handleCartClick}
            />
          </DesktopContentContainer>
        </DesktopMainContainer>
      </NewModal>

      {renderAllergensModal()}
    </>
  )
}

export default Product
