import {forwardRef, useImperativeHandle, useMemo, useRef} from 'react'
import styled from 'styled-components'

import {OrderContract} from 'src/types/api'
import OrdersListItems from './OrdersListItems'
import {DATE_FORMAT, dayjs} from 'src/utilities/dayjs'
import Text from 'src/components/Text'

const ORDERS_LIST_GAP = 16

interface OrdersListProps {
  orders?: OrderContract[]
}

export interface OrdersListRef {
  getScrollPosition: (date: string) => number | undefined
  getDate: (position: number) => string | undefined
}

const OrdersListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${ORDERS_LIST_GAP}px;
`

const DateContainer = styled.div`
  padding-bottom: 1rem;
`

const OrdersList = forwardRef<OrdersListRef, OrdersListProps>(({orders = []}, ref) => {
  const datePositions = useRef<{[date: string]: number}>({})
  const positionDates = useRef<{date: string; startPosition: number; endPosition: number}[]>([])

  const ordersObj = useMemo(() => {
    return orders.reduce<{[date: string]: OrderContract[]}>((obj, order) => {
      const date = dayjs(order.startTimeStamp).format(DATE_FORMAT)

      if (!obj[date]) {
        obj[date] = []
      }

      obj[date].push(order)

      return obj
    }, {})
  }, [orders])

  const setDatePositions = (itemRef: HTMLDivElement | null, date: string) => {
    if (!itemRef) {
      return
    }

    const offsetTop = itemRef.offsetTop
    const offsetHeight = itemRef.offsetHeight

    datePositions.current[date] = offsetTop

    positionDates.current.push({
      date,
      startPosition: offsetTop,
      endPosition: offsetTop + offsetHeight + ORDERS_LIST_GAP,
    })
  }

  const getScrollPosition = (date: string) => {
    return datePositions.current[date]
  }

  const getDate = (position: number) => {
    const foundPositionDate = positionDates.current.find(
      (positionDate) => position >= positionDate.startPosition && position < positionDate.endPosition,
    )
    if (!foundPositionDate) {
      return
    }

    return foundPositionDate.date
  }

  useImperativeHandle(
    ref,
    () => {
      return {getScrollPosition, getDate}
    },
    [],
  )

  return (
    <>
      {Object.entries(ordersObj).map(([date, orders]) => (
        <div ref={(itemRef) => setDatePositions(itemRef, date)} key={date}>
          <DateContainer>
            <Text type="productBoxName" align="center">
              {date}
            </Text>
          </DateContainer>

          <OrdersListItemsContainer>
            {orders.map((order) => (
              <OrdersListItems key={order.id} order={order} />
            ))}
          </OrdersListItemsContainer>
        </div>
      ))}
    </>
  )
})

export default OrdersList
