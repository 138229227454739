import {ReactNode} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'

interface LabelProps {
  children?: ReactNode
  visible?: boolean
}

interface TextStyledProps {
  visible: boolean
}

const TextStyled = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'ratingLabel',
})<TextStyledProps>`
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
`

const Label = (props: LabelProps) => {
  return <TextStyled visible={props.visible ?? true}>{props.children}</TextStyled>
}

export default Label
