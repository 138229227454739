import styled, {AnyStyledComponent} from 'styled-components'

import CartImg from 'src/assets/images/cart.png'
import Text from 'src/components/Text'

interface CartProps {
  count?: number
}

const Container = styled.div`
  position: relative;
`

const CartImgStyled = styled.img`
  width: 2rem;
  height: auto;
`

const CountContainer = styled.div`
  width: 1.375rem;
  height: 1.375rem;
  background-color: ${({theme}) => theme.colors.primaryText};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.5rem;
  top: -0.375rem;
`

const CountText = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'cartItemsNumber',
  color: 'alternativePrimaryText',
})``

const Cart = ({count = 0}: CartProps) => {
  return (
    <Container>
      <CountContainer>
        <CountText>{count}</CountText>
      </CountContainer>

      <CartImgStyled src={CartImg} alt="cart" />
    </Container>
  )
}

export default Cart
