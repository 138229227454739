import {memo} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'
import {useTranslation} from 'react-i18next'

import ConfirmPermissionIcon from 'src/assets/icons/confirm-permission.svg?react'
import Text from 'src/components/Text'
import ActionButton from './ActionButton'
import NewModal from './NewModal'
import {useMediaQuery} from 'src/utilities/hooks'

interface ConfirmAgeModalProps {
  isOpen: boolean
  age?: number
  onConfirm?: () => void
  onCancel?: () => void
  onBack?: () => void
}

const ContentContainer = styled.div`
  padding: 4.5rem 2.5rem 2rem 2.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 3rem 1.5rem 1rem 1.5rem;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 1.5rem 0 0.75rem 0;
`

const ButtonsContainer = styled.div`
  padding: 0.75rem 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.125rem;
  filter: drop-shadow(0px -2px 14px rgba(0, 0, 0, 0.07));
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};

  @media ${({theme}) => theme.queries.mobile} {
    padding: 0.75rem 1.5rem;
  }
`

const ConfirmPermissionIconStyled = styled(ConfirmPermissionIcon as unknown as AnyStyledComponent)`
  width: 8rem;
  height: 8rem;

  @media ${({theme}) => theme.queries.mobile} {
    width: 5.5rem;
    height: 5.5rem;
  }
`

const ConfirmAgeModal = (props: ConfirmAgeModalProps) => {
  const {t} = useTranslation()

  const {isMobile} = useMediaQuery()

  return (
    <NewModal width={isMobile ? '100%' : '30rem'} isOpen={props.isOpen} showCloseButton onRequestClose={props.onBack}>
      <ContentContainer>
        <IconContainer>
          <ConfirmPermissionIconStyled />
        </IconContainer>
        <TitleContainer>
          <Text type="checkoutPageTitle" align="center">
            {t('components.confirmAgeModal.title')}
          </Text>
        </TitleContainer>
        <Text type="checkoutPageOptionDescription" align="center">
          {t('components.confirmAgeModal.description', {age: props.age})}
        </Text>
      </ContentContainer>
      <ButtonsContainer>
        <ActionButton onClick={props.onCancel}>
          {t('components.confirmAgeModal.cancelButtonText', {age: props.age})}
        </ActionButton>
        <ActionButton selected onClick={props.onConfirm}>
          {t('components.confirmAgeModal.confirmButtonText', {age: props.age})}
        </ActionButton>
      </ButtonsContainer>
    </NewModal>
  )
}

export default memo(ConfirmAgeModal)
