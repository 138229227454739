import {MouseEventHandler, ReactNode, memo} from 'react'
import styled from 'styled-components'

interface OptionsListItemProps {
  LeftContentComponent: ReactNode
  CenterContentComponent: ReactNode
  RightContentComponent?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const OptionsListItemContainer = styled.button`
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem;
  border: none;
  cursor: pointer;
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};
  padding: 0 0.75rem 0 0;
  display: grid;
  grid-template-columns: 3.563rem 1fr auto;
  align-items: center;
  text-align: start;
  min-height: 2.813rem;
`

const OptionsListItemLeftContainer = styled.div`
  justify-self: center;
`

const OptionsListItemRightContainer = styled.div`
  padding-left: 0.75rem;
`

const OptionsListItem = (props: OptionsListItemProps) => {
  return (
    <OptionsListItemContainer onClick={props.onClick}>
      <OptionsListItemLeftContainer>{props.LeftContentComponent}</OptionsListItemLeftContainer>
      <div>{props.CenterContentComponent}</div>
      {!!props.RightContentComponent && (
        <OptionsListItemRightContainer>{props.RightContentComponent}</OptionsListItemRightContainer>
      )}
    </OptionsListItemContainer>
  )
}

export default memo(OptionsListItem)
