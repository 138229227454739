import styled from 'styled-components'

import Select, {SelectOption} from 'src/pages/DynamicPage/components/Select'
import LocationImg from 'src/assets/images/location.png'
import Text from 'src/components/Text'
import {useMediaQuery} from 'src/utilities/hooks'

interface RestaurantsListProps {
  title?: string
  options?: SelectOption[]
  activeOption?: SelectOption
  onRestaurantChange?: (tabletId: string) => void
}

const Container = styled.div`
  display: grid;
  row-gap: 2.5rem;

  @media ${({theme}) => theme.queries.mobile} {
    row-gap: 1.25rem;
  }
`

const RestaurantsList = ({title, options = [], activeOption, onRestaurantChange}: RestaurantsListProps) => {
  const {isMobile} = useMediaQuery()

  return (
    <Container>
      <Text type={isMobile ? 'mainTitleMobile' : 'mainTitleDesktop'} color="mainColor" fontFamily="secondary">
        {title}
      </Text>

      <Select
        optionsVisibleCount={3}
        options={options}
        option={activeOption}
        icon={LocationImg}
        onOptionSelect={(option) => onRestaurantChange?.(option.value)}
      />
    </Container>
  )
}

export default RestaurantsList
