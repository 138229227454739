import {memo, useCallback} from 'react'
import styled from 'styled-components'

import {ProductCategoryContract} from 'src/types/api'
import Text from 'src/components/Text'
import {mediaUrl} from 'src/utilities/functions'
import {sizes} from 'src/utilities/theme'

interface CategoriesVerticalListProps {
  categories: ProductCategoryContract[]
  onItemClick?: (categoryId: string) => void
}

interface CategoriesListItemProps {
  category: ProductCategoryContract
  onItemClick?: (categoryId: string) => void
}

const CategoriesListContainer = styled.div`
  display: grid;
  row-gap: 0.625rem;
`

const CategoriesListItemContainer = styled.button`
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};
  border-radius: 1.186rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.152453);
  padding: 0.438rem;
  border: none;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.875rem;
  align-items: center;

  :hover {
    background-color: ${({theme}) => theme.colors.buttons};
  }
`

const Image = styled.img`
  width: ${({theme}) => theme.sizes.categoriesListItemImgSize}px;
  height: ${({theme}) => theme.sizes.categoriesListItemImgSize}px;
  border-radius: 50%;
  overflow: hidden;
`

const CategoriesListItem = memo((props: CategoriesListItemProps) => {
  const handleItemClick = useCallback(() => {
    props.onItemClick?.(props.category.id!)
  }, [props])

  return (
    <CategoriesListItemContainer onClick={handleItemClick}>
      <Image
        src={mediaUrl(props.category.imageName, {
          w: sizes.categoriesListItemImgSize,
          h: sizes.categoriesListItemImgSize,
        })}
      />
      <Text type="verticalMenu">{props.category.title}</Text>
    </CategoriesListItemContainer>
  )
})

const CategoriesVerticalList = (props: CategoriesVerticalListProps) => {
  return (
    <CategoriesListContainer>
      {props.categories.map((category) => (
        <CategoriesListItem key={category.id} category={category} onItemClick={props.onItemClick} />
      ))}
    </CategoriesListContainer>
  )
}

export default memo(CategoriesVerticalList)
