import {ButtonHTMLAttributes, CSSProperties} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'

export const LIST_ITEM_HEIGHT = 50 as const

interface ListItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  item?: Item
  active?: boolean
  width?: CSSProperties['width']
  onItemClick?: (id: string) => void
}

export interface Item {
  id: string
  imgSrc: string
  title: string
}

interface ItemContainerProps {
  active?: boolean
  width?: CSSProperties['width']
}

const ItemContainer = styled.button<ItemContainerProps>`
  min-width: ${({width}) => width};
  max-width: ${({width}) => width};
  height: ${LIST_ITEM_HEIGHT}px;
  background-color: ${({theme, active}) => (active ? theme.colors.buttons : theme.colors.whiteItem)};
  border-radius: 6.25rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.15);
  padding: 0.25rem;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.75rem;
  align-items: center;
  border: none;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 100ms;
`

const ItemImg = styled.img`
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.15);
`

const TitleContainer = styled.div`
  padding-right: 0.5rem;
`

const Title = styled(Text as unknown as AnyStyledComponent).attrs({
  style: {
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
})``

const ListItem = ({item, active, width = '12.3rem', onItemClick, ...props}: ListItemProps) => {
  const handleClick = () => {
    if (!item) {
      return
    }

    onItemClick?.(item.id)
  }

  return (
    <ItemContainer type="button" active={active} width={width} onClick={handleClick} {...props}>
      <ItemImg src={item?.imgSrc} alt={item?.title} />

      <TitleContainer>
        <Title type="menuItems">{item?.title}</Title>
      </TitleContainer>
    </ItemContainer>
  )
}

export default ListItem
