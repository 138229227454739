import {forwardRef, useImperativeHandle, useRef} from 'react'
import styled from 'styled-components'

import Text from 'src/components/Text'
import {ProductCategoryContract} from 'src/types/api'
import IconButton from 'src/components/IconButton'
import CategoriesVerticalList from 'src/pages/Home/components/CategoriesVerticalList'

const CATEGORIES_LIST_PADDING_X = 8 as const

export interface MobileCategoriesHorizontalListRef {
  scrollToCategory: (categoryId: string) => void
  scrollToTop: () => void
}

interface MobileCategoriesHorizontalListProps {
  categories: ProductCategoryContract[]
  activeCategoryId: string
  expanded?: boolean
  verticalListHeight?: number
  onCategoryClick: (categoryId: string) => void
  onExpandClick?: () => void
}

interface CategoriesListItemProps {
  category: ProductCategoryContract
  active: boolean
  onCategoryClick: (categoryId: string) => void
}

interface CategoriesListItemContainerProps {
  active: boolean
}

interface ProductsListCategoryData {
  categoryId: string
  scrollLeft: number
}

interface CategoriesVerticalListContainerProps {
  height?: number
}

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-items: center;
  height: ${({theme}) => theme.sizes.categoriesListContainerHeight}px;
  background-color: ${({theme}) => theme.colors.background};
  padding: 0 0.25rem 0 0.75rem;
  position: relative;
`

const CategoriesListContainer = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  height: 100%;
  padding: 0 0.5rem;
  position: relative;
`

const CategoriesListItemContainer = styled.button<CategoriesListItemContainerProps>`
  padding: 0 ${CATEGORIES_LIST_PADDING_X}px;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 1.344rem;
  white-space: nowrap;
  background-color: ${({theme, active}) => (active ? theme.colors.secondaryText : theme.colors.alternativePrimaryText)};
  border: none;
  cursor: pointer;
  height: ${({theme}) => theme.sizes.iconButtonSize}px;
`

const CategoriesVerticalListContainer = styled.div<CategoriesVerticalListContainerProps>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${({theme}) => theme.colors.background};
  padding: 0.625rem 0.75rem 4.125rem 0.75rem;
  overflow-y: auto;
  box-sizing: border-box;
  height: ${({height}) => height ?? 0}px;
`

const CategoriesListItem = forwardRef<HTMLButtonElement, CategoriesListItemProps>((props, ref) => {
  const handleCategoryClick = () => {
    props.onCategoryClick(props.category.id!)
  }

  return (
    <CategoriesListItemContainer ref={ref} type="button" active={props.active} onClick={handleCategoryClick}>
      <Text type="productPageDescription" color={props.active ? 'alternativePrimaryText' : 'primaryText'}>
        {props.category.title}
      </Text>
    </CategoriesListItemContainer>
  )
})

const MobileCategoriesHorizontalList = forwardRef<
  MobileCategoriesHorizontalListRef,
  MobileCategoriesHorizontalListProps
>((props, ref) => {
  const categories = useRef<{[categoryId: string]: ProductsListCategoryData}>({})
  const categoriesListContainerRef = useRef<HTMLDivElement>(null)
  const categoriesVerticalListContainerRef = useRef<HTMLDivElement>(null)

  const setCategories = (data: {itemRef: HTMLButtonElement | null; categoryId?: string}) => {
    const itemRef = data.itemRef
    const categoryId = data.categoryId

    if (!itemRef || !categoryId) {
      return
    }

    const scrollLeft = itemRef.offsetLeft - CATEGORIES_LIST_PADDING_X

    categories.current[categoryId] = {
      categoryId,
      scrollLeft,
    }
  }

  const scrollToCategory = (categoryId: string) => {
    const category = categories.current[categoryId]

    if (!category || !categoriesListContainerRef.current) {
      return
    }

    categoriesListContainerRef.current.scroll({left: category.scrollLeft, behavior: 'smooth'})
  }

  const scrollToTop = () => {
    categoriesVerticalListContainerRef.current?.scroll({top: 0, behavior: 'smooth'})
  }

  useImperativeHandle(
    ref,
    () => {
      return {scrollToCategory, scrollToTop}
    },
    [],
  )

  return (
    <MainContainer>
      <IconButton name={props.expanded ? 'menu-close' : 'chevron-down'} onClick={props.onExpandClick} />

      <CategoriesListContainer ref={categoriesListContainerRef}>
        {props.categories.map((category) => (
          <CategoriesListItem
            key={category.id}
            ref={(itemRef) => setCategories({itemRef, categoryId: category.id})}
            category={category}
            active={category.id === props.activeCategoryId}
            onCategoryClick={props.onCategoryClick}
          />
        ))}
      </CategoriesListContainer>

      {!!props.expanded && (
        <CategoriesVerticalListContainer ref={categoriesVerticalListContainerRef} height={props.verticalListHeight}>
          <CategoriesVerticalList categories={props.categories} onItemClick={props.onCategoryClick} />
        </CategoriesVerticalListContainer>
      )}
    </MainContainer>
  )
})

export default MobileCategoriesHorizontalList
