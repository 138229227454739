import {ReactNode} from 'react'
import styled from 'styled-components'

import Text from 'src/components/Text'

interface ListHeaderProps {
  children?: ReactNode
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.125rem;
  align-items: center;
`

const Divider = styled.div`
  background-color: ${({theme}) => theme.colors.secondaryText};
  opacity: 0.5;
  height: 1px;
`

const ListHeader = ({children}: ListHeaderProps) => {
  return (
    <Container>
      <Text type="productsCategoryTitle" color="secondaryText">
        {children}
      </Text>

      <Divider />
    </Container>
  )
}

export default ListHeader
