import {forwardRef, Fragment, memo, MouseEvent, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import Text from 'src/components/Text'
import {OptionSetContract, OptionSetType, ProductContract, ProductType} from 'src/types/api'
import Checkbox from 'src/components/Checkbox'
import SelectOption from 'src/components/SelectOption'
import TrashIcon from 'src/assets/icons/trash.svg?react'
import {CartItemOptionSet} from 'src/models/cart'
import OptionsListItem from './OptionsListItem'
import OptionsListItemWithInput from './OptionsListItemWithInput'
import {RootState} from 'src/utilities/store'
import {selectIsLoyaltyCardType} from 'src/models/catalog'
import {priceWithCurrency} from 'src/utilities/functions'
import {useChoiceCountTranslation} from 'src/utilities/hooks'

export interface LoyaltyCardData {
  min: number
  max: number
}

interface OptionsListProps {
  hasError: boolean
  optionSet: OptionSetContract
  cartItemOptionSets?: CartItemOptionSet
  cartItemProductId?: string
  onOptionAdd: (data: {optionSet: OptionSetContract; option: ProductContract}) => void
  onOptionRemove: (data: {optionSetId: string; optionId: string}) => void
}

const TitleContainer = styled.div`
  padding-bottom: 0.438rem;
`

const OptionsContainer = styled.div`
  padding-top: 0.688rem;
  display: grid;
  gap: 0.75rem;

  @media ${({theme}) => theme.queries.desktop} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const TrashButtonContainer = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
`

const OptionsList = forwardRef<HTMLDivElement, OptionsListProps>((props, ref) => {
  const {t} = useTranslation()

  const choiceCountT = useChoiceCountTranslation(props.optionSet.maxSelections)

  const isLoyaltyCardType = useSelector((state: RootState) =>
    selectIsLoyaltyCardType(state, props?.cartItemProductId ?? ''),
  )

  const loyaltyCardData: LoyaltyCardData | undefined = useMemo(() => {
    if (!isLoyaltyCardType) {
      return
    }

    const prices: number[] = [...(props?.optionSet?.options ?? [])]
      .map((option) => option?.price ?? 0)
      .sort((a, b) => a - b)

    const min = prices.length > 1 ? prices[0] : 1
    const max = prices.length ? prices[prices.length - 1] : 1

    return {min, max}
  }, [isLoyaltyCardType, props?.optionSet?.options])

  const isOptionSelected = useCallback(
    (optionId: string) => !!props.cartItemOptionSets?.[props.optionSet.id!]?.options?.[optionId],
    [props.cartItemOptionSets, props.optionSet.id],
  )

  const optionCount = useCallback(
    (optionId: string) => props.cartItemOptionSets?.[props.optionSet.id!]?.options?.[optionId]?.count ?? 0,
    [props.cartItemOptionSets, props.optionSet.id],
  )

  const handleOptionAdd = useCallback(
    (option: ProductContract) => {
      if (isLoyaltyCardType) {
        const optionSet: OptionSetContract = {
          ...props?.optionSet,
          options: props?.optionSet?.options?.map((o) => (o?.id === option?.id ? option : o)) ?? [],
        }

        props.onOptionAdd({optionSet, option})
      }

      props.onOptionAdd({optionSet: props.optionSet, option})
    },
    [isLoyaltyCardType, props],
  )

  const handleOptionRemove = useCallback(
    (data: {e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>; optionId: string}) => {
      data.e.stopPropagation()
      props.onOptionRemove({optionSetId: props.optionSet.id!, optionId: data.optionId})
    },
    [props],
  )

  const renderOptionCenterComponent = useCallback(
    (option: ProductContract) => {
      return (
        <>
          <Text type="productPageOptionSelection">{option.title}</Text>
          {!!props.cartItemProductId && !isLoyaltyCardType && option.price! > 0 && (
            <Text type="productPageOptionSelection" color="secondaryText">
              {` + ${priceWithCurrency(option.price)}`}
            </Text>
          )}
        </>
      )
    },
    [isLoyaltyCardType, props.cartItemProductId],
  )

  const renderSingleOption = useCallback(
    (option: ProductContract, optionSetId: string) => {
      switch (option?.productType) {
        case ProductType.FlexiblePrice:
          const optionPriceInCart = props?.cartItemOptionSets?.[optionSetId]?.options?.[option?.id!]?.option?.price ?? 0

          return (
            <OptionsListItemWithInput
              defaultValue={String(optionPriceInCart || '')}
              title={option?.title!}
              loyaltyCardData={loyaltyCardData}
              selected={isOptionSelected(option?.id!)}
              onItemClick={() => handleOptionAdd({...option, price: optionPriceInCart})}
              onValueChange={(value) => handleOptionAdd({...option, price: value})}
            />
          )
        default:
          return (
            <OptionsListItem
              CenterContentComponent={renderOptionCenterComponent(option)}
              LeftContentComponent={<SelectOption selected={isOptionSelected(option?.id!)} />}
              onClick={() => handleOptionAdd(option)}
            />
          )
      }
    },
    [handleOptionAdd, isOptionSelected, loyaltyCardData, props?.cartItemOptionSets, renderOptionCenterComponent],
  )

  const renderOptions = useCallback(() => {
    switch (props.optionSet.type) {
      case OptionSetType.Single:
        return props.optionSet.options?.map((option) => (
          <Fragment key={option?.id}>{renderSingleOption(option, props?.optionSet?.id!)}</Fragment>
        ))
      case OptionSetType.Multi:
        return props.optionSet.options?.map((option) => (
          <OptionsListItem
            key={option.id}
            CenterContentComponent={renderOptionCenterComponent(option)}
            LeftContentComponent={<Checkbox selected={isOptionSelected(option.id!)} />}
            onClick={() => handleOptionAdd(option)}
          />
        ))
      case OptionSetType.Extra:
        return props.optionSet.options?.map((option) => (
          <OptionsListItem
            key={option.id}
            CenterContentComponent={renderOptionCenterComponent(option)}
            LeftContentComponent={
              !!optionCount(option.id!) && (
                <Text type="productPageOptionName" color="selectedOption">
                  {optionCount(option.id!)}x
                </Text>
              )
            }
            RightContentComponent={
              !!optionCount(option.id!) && (
                <TrashButtonContainer onClick={(e) => handleOptionRemove({e, optionId: option.id!})}>
                  <TrashIcon />
                </TrashButtonContainer>
              )
            }
            onClick={() => handleOptionAdd(option)}
          />
        ))
      default:
        return null
    }
  }, [
    handleOptionAdd,
    handleOptionRemove,
    isOptionSelected,
    optionCount,
    props.optionSet?.id,
    props.optionSet.options,
    props.optionSet.type,
    renderOptionCenterComponent,
    renderSingleOption,
  ])

  return (
    <div ref={ref}>
      <TitleContainer>
        <Text type="productPageOptionName" color={props.hasError ? 'error' : 'primaryText'}>
          {props.optionSet.title}
          {props.hasError ? ` (${t('pages.product.components.optionsList.errorText')})` : ''}
        </Text>
      </TitleContainer>
      {props.optionSet.type !== OptionSetType.Single && props.optionSet.options!.length! > 1 && (
        <Text type="productPageOptionDescription" color="secondaryText">
          {choiceCountT}
        </Text>
      )}
      <OptionsContainer>{renderOptions()}</OptionsContainer>
    </div>
  )
})

export default memo(OptionsList)
