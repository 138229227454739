import styled from 'styled-components'

import MenuCloseIcon from 'src/assets/icons/menu-close.svg'
import Text from 'src/components/Text'
import {useMediaQuery} from 'src/utilities/hooks'
import {MAX_UPLOAD_FILE_SIZE} from 'src/utilities/constants'

interface FileItemsListProps {
  files?: File[]
  loading?: boolean
  showErrors?: boolean
  onRemoveClick?: (file: File) => void
}

const FileItemsContainer = styled.div`
  display: grid;
  row-gap: 1.25rem;
`

const FileItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.25rem;
  justify-content: start;
  align-items: center;
`

const RemoveButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  padding: 0;
  background-color: transparent;
  display: block;
  width: 1rem;
  height: 1rem;
  line-height: 0;

  :enabled {
    cursor: pointer;
  }
`

const RemoveButtonImage = styled.img.attrs({
  alt: 'remove-button',
  src: MenuCloseIcon,
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const FileItemsList = ({files, loading, showErrors, onRemoveClick}: FileItemsListProps) => {
  const {isMobile} = useMediaQuery()

  if (!files?.length) {
    return null
  }

  return (
    <FileItemsContainer>
      {files.map((file, index) => (
        <FileItemContainer key={index}>
          <Text
            type={isMobile ? 'descriptionTextMobile' : 'buttonText'}
            color={showErrors && file.size > MAX_UPLOAD_FILE_SIZE ? 'error' : 'primaryText'}
          >
            {file.name}
          </Text>

          <RemoveButton disabled={loading}>
            <RemoveButtonImage onClick={() => onRemoveClick?.(file)} />
          </RemoveButton>
        </FileItemContainer>
      ))}
    </FileItemsContainer>
  )
}

export default FileItemsList
