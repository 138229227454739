import {memo, MouseEventHandler, ReactNode} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'
import ArrowRightIcon from 'src/assets/icons/arrow-right.svg?react'
import MenuCloseIcon from 'src/assets/icons/menu-close.svg'

interface AddInfoItemProps {
  title?: string
  itemTitle: string
  itemDescription: string
  showRemoveButton?: boolean
  Icon: ReactNode
  onItemClick?: () => void
  onRemoveClick?: () => void
}

const MainContainer = styled.div`
  display: grid;
  row-gap: 1.125rem;
`

const CardContainer = styled.button`
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  border-radius: 0.563rem;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
  align-items: center;
  border: none;
  cursor: pointer;
`

const CardCenterContainer = styled.div`
  display: grid;
  row-gap: 0.4rem;
`

const Description = styled(Text as unknown as AnyStyledComponent).attrs({
  style: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})``

const RemoveButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: block;
  width: 1rem;
  height: 1rem;

  :enabled {
    cursor: pointer;
  }
`

const RemoveButtonImage = styled.img.attrs({
  alt: 'remove-button',
  src: MenuCloseIcon,
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const AddInfoItem = (props: AddInfoItemProps) => {
  const handleRemoveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    props.onRemoveClick?.()
  }

  return (
    <MainContainer>
      {!!props.title && <Text type="productPageOptionName">{props.title}</Text>}
      <CardContainer onClick={props.onItemClick}>
        {props.Icon}
        <CardCenterContainer>
          <Text type="checkoutPageOptionTitle">{props.itemTitle}</Text>
          <Description type="checkoutPageOptionDescription" color="secondaryText">
            {props.itemDescription}
          </Description>
        </CardCenterContainer>
        {props.showRemoveButton ? (
          <RemoveButton onClick={handleRemoveClick}>
            <RemoveButtonImage />
          </RemoveButton>
        ) : props.onItemClick ? (
          <ArrowRightIcon />
        ) : null}
      </CardContainer>
    </MainContainer>
  )
}

export default memo(AddInfoItem)
