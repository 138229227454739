import styled from 'styled-components'
import {memo, MouseEvent} from 'react'

import PlusIcon from 'src/assets/icons/plus.svg?react'
import MinusIcon from 'src/assets/icons/minus.svg?react'
import Text from './Text'

interface ProductCounterProps {
  count?: number
  addDisabled?: boolean
  onAdd?: () => void
  onRemove?: () => void
}

interface ProductCounterButtonProps {
  type: 'add' | 'remove'
  disabled?: boolean
  onItemClick?: () => void
}

const ProductCounterContainer = styled.div`
  display: grid;
  grid-template-columns: auto 2rem auto;
  column-gap: 0.25rem;
  align-items: center;
  justify-content: start;
`

const ProductCounterButtonContainer = styled.button`
  border: none;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.alternativePrimaryText};
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  :enabled {
    cursor: pointer;
  }

  :disabled {
    opacity: 0.5;
  }
`

const ProductCounterButton = memo((props: ProductCounterButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation()
    props.onItemClick?.()
  }

  return (
    <ProductCounterButtonContainer disabled={props.disabled} onClick={handleClick}>
      {props.type === 'add' ? <PlusIcon /> : <MinusIcon />}
    </ProductCounterButtonContainer>
  )
})

const ProductCounter = (props: ProductCounterProps) => {
  return (
    <ProductCounterContainer>
      <ProductCounterButton type="remove" onItemClick={props.onRemove} />
      <Text type="productBoxName" align="center">
        {props.count ?? 0}
      </Text>
      <ProductCounterButton type="add" disabled={props.addDisabled} onItemClick={props.onAdd} />
    </ProductCounterContainer>
  )
}

export default memo(ProductCounter)
