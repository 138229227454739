import styled from 'styled-components'

import {Color} from 'src/utilities/theme'

interface ActionButtonProps {
  color?: Color
}

const ActionButton = styled.button<ActionButtonProps>`
  display: block;
  width: 100%;
  border: none;
  padding: 0;
  background-color: ${({theme, color = 'primaryItem'}) => theme.colors[color]};
  height: 3rem;
  border-radius: 1.5rem;
  overflow: hidden;
  color: ${({theme}) => theme.colors.primaryText};
  box-shadow: ${({theme, color = 'primaryItem'}) => {
    const shadowColor = theme.colors[color]

    return `0 2px 3px 0 ${shadowColor}29, 0 3px 12px 0 ${shadowColor}66;`
  }};
  ${({theme}) => ({...theme.typography.primaryButton})}

  :enabled {
    cursor: pointer;
  }
`

export default ActionButton
