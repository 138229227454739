import {memo} from 'react'
import styled from 'styled-components'

import CheckIcon from 'src/assets/icons/check.svg?react'
import CheckSelectedIcon from 'src/assets/icons/check-selected.svg?react'

interface CheckboxProps {
  selected?: boolean
  onSelect?: () => void
}

const Container = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  display: flex;
`

const Checkbox = (props: CheckboxProps) => {
  return <Container onClick={props.onSelect}>{!props?.selected ? <CheckIcon /> : <CheckSelectedIcon />}</Container>
}

export default memo(Checkbox)
