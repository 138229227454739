import {memo, MouseEventHandler, ReactNode} from 'react'
import styled from 'styled-components'

import Text from './Text'
import {Color} from 'src/utilities/theme'

interface TextButtonProps {
  color?: Color
  disabled?: boolean
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Container = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
`

const TextButton = (props: TextButtonProps) => {
  return (
    <Container disabled={props.disabled} onClick={props.onClick}>
      <Text type="checkoutPageOptionDescription" color={props.color ?? 'selectedOption'}>
        {props.children}
      </Text>
    </Container>
  )
}

export default memo(TextButton)
