import styled from 'styled-components'

import StarImg from 'src/assets/images/star.png'
import StarFilledImg from 'src/assets/images/star-filled.png'

const STAR_OUTER_CONTAINER_SIZE = '2.125rem'
const STAR_OUTER_CONTAINER_SM_SIZE = '1.5rem'
const STAR_INNER_CONTAINER_SIZE = '3.54rem'
const STAR_INNER_CONTAINER_SM_SIZE = '2.49rem'
const STAR_ICON_SIZE = STAR_INNER_CONTAINER_SIZE
const STAR_ICON_SM_SIZE = STAR_INNER_CONTAINER_SM_SIZE

interface StarsProps {
  ratedRating?: number
  disabled?: boolean
  tipped?: boolean
  onRate?: (rating: number) => void
}

interface StarsContainerProps {
  tipped?: boolean
}

interface StarProps extends StarsProps {
  rating: number
}

interface StarOuterContainerProps {
  disabled?: boolean
  tipped?: boolean
}

interface StarInnerContainerProps {
  tipped?: boolean
}

interface StarImgStyledProps {
  tipped?: boolean
}

const StarsContainer = styled.div<StarsContainerProps>`
  display: flex;
  column-gap: ${({tipped}) => (tipped ? 0.45 : 0.625)}rem;
`

const StarOuterContainer = styled.div<StarOuterContainerProps>`
  width: ${({tipped}) => (tipped ? STAR_OUTER_CONTAINER_SM_SIZE : STAR_OUTER_CONTAINER_SIZE)};
  height: ${({tipped}) => (tipped ? STAR_OUTER_CONTAINER_SM_SIZE : STAR_OUTER_CONTAINER_SIZE)};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  cursor: pointer;
`

const StarInnerContainer = styled.div<StarInnerContainerProps>`
  width: ${({tipped}) => (tipped ? STAR_INNER_CONTAINER_SM_SIZE : STAR_INNER_CONTAINER_SIZE)};
  height: ${({tipped}) => (tipped ? STAR_INNER_CONTAINER_SM_SIZE : STAR_INNER_CONTAINER_SIZE)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${({tipped}) => (tipped ? 0.26 : 0.37)}rem;
  pointer-events: none;
`

const StarImgStyled = styled.img<StarImgStyledProps>`
  width: ${({tipped}) => (tipped ? STAR_ICON_SM_SIZE : STAR_ICON_SIZE)};
  height: ${({tipped}) => (tipped ? STAR_ICON_SM_SIZE : STAR_ICON_SIZE)};
  object-fit: contain;
`

const Star = (props: StarProps) => {
  const rated = (props.ratedRating ?? 0) >= props.rating

  const handleRate = () => {
    props.onRate?.(props.rating)
  }

  return (
    <StarOuterContainer tipped={props.tipped} disabled={props.disabled} onClick={handleRate}>
      <StarInnerContainer tipped={props.tipped}>
        <StarImgStyled src={rated ? StarFilledImg : StarImg} alt={`star-${props.rating}`} tipped={props.tipped} />
      </StarInnerContainer>
    </StarOuterContainer>
  )
}

const Stars = (props: StarsProps) => {
  return (
    <StarsContainer tipped={props.tipped}>
      {Array.from({length: 5}).map((_, index) => (
        <Star
          key={index}
          ratedRating={props.ratedRating}
          rating={index + 1}
          disabled={props.disabled}
          tipped={props.tipped}
          onRate={props.onRate}
        />
      ))}
    </StarsContainer>
  )
}

export default Stars
