import styled from 'styled-components'

const DOT_SIZE = '0.15625rem'

const DotsContainer = styled.div`
  display: flex;
  column-gap: 0.21875rem;
  justify-content: center;
`

const Dot = styled.div`
  width: ${DOT_SIZE};
  height: ${DOT_SIZE};
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primaryText};
`

const Dots = () => {
  return (
    <DotsContainer>
      {Array.from({length: 3}).map((_, index) => (
        <Dot key={index} />
      ))}
    </DotsContainer>
  )
}

export default Dots
