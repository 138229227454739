import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import Text from 'src/components/Text'

const INITIAL_FORM_DATA: FormData = {
  email: '',
  confirmEmail: '',
}

export interface LoyaltyCardEmailRef {
  submit: () => string | undefined
  reset: () => void
}

interface LoyaltyCardEmailProps {
  defaultEmail?: string
}

interface FormData {
  email: string
  confirmEmail: string
}

interface InputData {
  name: keyof FormData
  placeholder: string
}

interface InputErrors {
  email: boolean
  confirmEmail: boolean
}

interface InputProps {
  hasError?: boolean
}

const DescriptionContainer = styled.div`
  padding: 1rem 0 1.5rem 0;

  @media ${({theme}) => theme.queries.mobile} {
    padding: 1rem 0;
  }
`

const InputsContainer = styled.form`
  display: grid;
  row-gap: 1rem;
`

const Input = styled.input<InputProps>`
  height: 2.813rem;
  padding: 0 0.75rem;
  border: none;
  border-radius: 0.563rem;
  overflow: hidden;
  outline: none;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.043679);
  background-color: ${({theme}) => theme.colors.whiteItem};
  color: ${({theme, hasError}) => (hasError ? theme.colors.error : theme.colors.primaryText)};
  ${({theme}) => ({...theme.typography.placeholder})};

  ::placeholder {
    color: ${({theme, hasError}) => (hasError ? theme.colors.error : theme.colors.secondaryText)};
    opacity: 0.8;
  }
`

const FormButton = styled.button.attrs({
  type: 'submit',
})`
  display: none;
`

const LoyaltyCardEmail = forwardRef<LoyaltyCardEmailRef, LoyaltyCardEmailProps>(({defaultEmail}, ref) => {
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA)
  const [validate, setValidate] = useState<boolean>(false)

  const formButtonRef = useRef<HTMLButtonElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const inputRefs = useRef<{[key in InputData['name']]?: HTMLInputElement}>({})

  const {t} = useTranslation()

  const inputsData: InputData[] = [
    {
      name: 'email',
      placeholder: t('pages.product.components.loyaltyCardEmail.inputs.email.placeholder'),
    },
    {
      name: 'confirmEmail',
      placeholder: t('pages.product.components.loyaltyCardEmail.inputs.confirmEmail.placeholder'),
    },
  ]

  const inputErrors: InputErrors = useMemo(
    () => ({
      email: !formData.email,
      confirmEmail: !formData.confirmEmail || formData.email !== formData.confirmEmail,
    }),
    [formData.confirmEmail, formData.email],
  )

  const hasErrors = useMemo(() => Object.values(inputErrors).some((inputError) => inputError), [inputErrors])

  const setInputRefs = (inputRef: HTMLInputElement | null) => {
    if (!inputRef) {
      return
    }

    inputRefs.current = {...inputRefs.current, [inputRef.name]: inputRef}
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormData((prevFormData) => ({...prevFormData, [e.target.name]: e.target.value}))
  }

  const handleFormSubmit = useCallback(() => {
    setValidate(true)

    formButtonRef.current?.click()

    if (hasErrors || !formRef.current?.checkValidity()) {
      return
    }

    return formData.email
  }, [formData.email, hasErrors])

  const handleFormReset = () => {
    setFormData(INITIAL_FORM_DATA)

    setValidate(false)
  }

  useEffect(() => {
    if (!defaultEmail) {
      return
    }

    setFormData({email: defaultEmail, confirmEmail: defaultEmail})
  }, [defaultEmail])

  useImperativeHandle(
    ref,
    () => {
      return {submit: handleFormSubmit, reset: handleFormReset}
    },
    [handleFormSubmit],
  )

  useEffect(() => {
    if (formData.email !== formData.confirmEmail) {
      inputRefs.current.confirmEmail?.setCustomValidity(
        t('pages.product.components.loyaltyCardEmail.inputs.confirmEmail.emailsDoNotMatchMsg'),
      )
      return
    }

    inputRefs.current.confirmEmail?.setCustomValidity('')
  }, [formData.confirmEmail, formData.email, t])

  return (
    <div>
      <Text type="productPageOptionName">{t('pages.product.components.loyaltyCardEmail.title')}</Text>

      <DescriptionContainer>
        <Text type="productPageDescription" color="secondaryText">
          {t('pages.product.components.loyaltyCardEmail.description')}
        </Text>
      </DescriptionContainer>

      <InputsContainer ref={formRef} onSubmit={(e) => e.preventDefault()}>
        {inputsData.map((inputData, index) => (
          <Input
            key={index}
            ref={setInputRefs}
            type="email"
            required
            name={inputData.name}
            value={formData[inputData.name]}
            hasError={inputErrors[inputData.name] && validate}
            onChange={handleInputChange}
            placeholder={inputData.placeholder}
          />
        ))}

        <FormButton ref={formButtonRef} />
      </InputsContainer>
    </div>
  )
})

export default LoyaltyCardEmail
