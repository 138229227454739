import styled from 'styled-components'
import {ChangeEventHandler, useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import SelectOption from 'src/components/SelectOption'
import OptionsListItem from './OptionsListItem'
import Text from 'src/components/Text'
import {LoyaltyCardData} from './OptionsList'
import {allowOnlyIntegers} from 'src/utilities/functions'

interface OptionsListItemWithInputProps {
  defaultValue?: string
  title?: string
  loyaltyCardData?: LoyaltyCardData
  selected?: boolean
  onItemClick?: () => void
  onValueChange?: (value: number) => void
}

interface InputProps {
  hasError?: boolean
}

const Input = styled.input<InputProps>`
  border: none;
  outline: none;
  color: ${({theme, hasError}) => (hasError ? theme.colors.error : theme.colors.primaryText)};
  display: block;
  width: 100%;
  ${({theme}) => ({...theme.typography.productPageOptionSelection})};

  ::placeholder {
    color: ${({theme}) => theme.colors.secondaryText};
  }
`

const OptionsListItemWithInput = ({
  defaultValue,
  title,
  loyaltyCardData,
  selected,
  onItemClick,
  onValueChange,
}: OptionsListItemWithInputProps) => {
  const [value, setValue] = useState<string>(defaultValue ?? '')

  const {t} = useTranslation()

  const isValueValid = useCallback(
    (sum: number) => {
      return sum >= (loyaltyCardData?.min ?? 0) && sum <= (loyaltyCardData?.max ?? 0)
    },
    [loyaltyCardData?.max, loyaltyCardData?.min],
  )

  const hasError = useMemo(() => {
    if (!value) {
      return false
    }

    return !isValueValid(Number(value))
  }, [isValueValid, value])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value

    setValue(value)

    const sum = Number(value)

    onValueChange?.(isValueValid(sum) ? sum : 0)
  }

  const renderCenterContentComponent = () => {
    if (selected) {
      return (
        <Input
          hasError={hasError}
          type="number"
          value={value}
          placeholder={t('pages.product.components.optionsListItemWithInput.placeholder', {
            min: loyaltyCardData?.min,
            max: loyaltyCardData?.max,
          })}
          min={loyaltyCardData?.min}
          max={loyaltyCardData?.max}
          onChange={handleChange}
          onKeyDown={allowOnlyIntegers}
        />
      )
    }

    return <Text type="productPageOptionSelection">{title}</Text>
  }

  useEffect(() => {
    if (!defaultValue) {
      return
    }

    setValue(defaultValue)
  }, [defaultValue])

  return (
    <OptionsListItem
      LeftContentComponent={<SelectOption selected={selected} />}
      CenterContentComponent={renderCenterContentComponent()}
      onClick={onItemClick}
    />
  )
}

export default OptionsListItemWithInput
