import {ComponentPropsWithRef, forwardRef} from 'react'
import styled from 'styled-components'

interface TextareaProps extends ComponentPropsWithRef<'textarea'> {
  error?: boolean
  height?: string
}

interface TextareaStyledProps {
  error?: boolean
  height?: string
}

const TextareaStyled = styled.textarea<TextareaStyledProps>`
  ${({theme}) => ({...theme.typography.buttonText})}
  display: block;
  width: 100%;
  height: ${({height}) => height ?? '6.25rem'};
  min-height: ${({height}) => height ?? '6.25rem'};
  padding: 0.875rem 0.75rem 0.875rem 1.25rem;
  box-sizing: border-box;
  background-color: ${({theme}) => theme.colors.whiteItem};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({theme, error}) => (error ? theme.colors.error : theme.colors.whiteItem)};
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.125rem;
  outline: none;
  color: ${({theme}) => theme.colors.primaryText};
  resize: vertical;

  ::placeholder {
    color: ${({theme}) => theme.colors.primaryText};
    opacity: 0.7;
  }

  @media ${({theme}) => theme.queries.mobile} {
    font-size: 1rem;
    padding: 0.9375rem 0.75rem 0.9375rem 1.25rem;
  }
`

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({error, height, ...props}, ref) => {
  return <TextareaStyled ref={ref} error={error} height={height} {...props} />
})

export default Textarea
