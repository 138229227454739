import {memo, MouseEventHandler, ReactNode} from 'react'
import styled, {AnyStyledComponent} from 'styled-components'

import Text from 'src/components/Text'

interface ActionButtonProps {
  selected?: boolean
  disabled?: boolean
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

interface ButtonProps {
  selected?: boolean
}

interface TextStyledProps {
  selected?: boolean
}

const Button = styled.button<ButtonProps>`
  border: none;
  padding: 0;
  cursor: pointer;
  height: 2.75rem;
  border-radius: 1.375rem;
  background-color: ${({theme, selected}) => (selected ? theme.colors.selectedOption : theme.colors.buttons)};

  @media ${({theme}) => theme.queries.mobile} {
    height: 2.5rem;
  }
`

const TextStyled = styled(Text as unknown as AnyStyledComponent)<TextStyledProps>`
  opacity: ${({selected}) => (selected ? 1 : 0.4)};
`

const ActionButton = (props: ActionButtonProps) => {
  return (
    <Button selected={props.selected} disabled={props.disabled} onClick={props.onClick}>
      <TextStyled
        type="checkoutPageButton"
        color={props.selected ? 'alternativePrimaryText' : 'selectedOption'}
        selected={props.selected}
        uppercase
      >
        {props.children}
      </TextStyled>
    </Button>
  )
}

export default memo(ActionButton)
